import { ampli } from '../../Ampli';
import { AccumulatedMetricsItem } from '../../Sections/DashboardSections/useMetrics';
import {
  pageViewEventProperties,
  transformPageViewToUserProperties,
} from './eventProperties';

export const trackOverviewPageViewed = (
  accumulatedMetricsItems: AccumulatedMetricsItem[],
  currency: string,
  fromDate: Date,
  toDate: Date,
  userId?: string
) => {
  const properties = pageViewEventProperties(
    accumulatedMetricsItems,
    currency,
    fromDate,
    toDate
  );
  const userProperties = transformPageViewToUserProperties(properties);

  if (userId) ampli.identify(userId, userProperties);
  ampli.overviewPageViewed(properties);

  return properties;
};
