import { Button } from '@holdbar-com/pixel';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useResponsive from '../../../../../../Hooks/layout/useResponsive';
import { useExperience } from '../../../../../../Hooks/useExperience';
import { trackEventFlowBookingStepCompleted } from '../../../../../../tracking/events/flow/booking/trackEventFlowBookingStepCompleted';
import { useEventUpsert } from '../../../domain/event_upsert_context';
import { EventUpsertContent } from '../../layout/EventUpsertContent';
import { EventUpsertBookingForm } from './booking_form/EventUpsertBookingForm';
import {
  BookingFormData,
  useBookingFormValidation,
} from './booking_form/use_booking_form_validation';

export const EventUpsertBooking = () => {
  const { t } = useTranslation();
  const { updateEventStepLatest, updateMultipleEventFields, currentEvent } =
    useEventUpsert();
  const { isSm } = useResponsive();
  const {
    experiences: { data: experiences },
  } = useExperience();

  const formValidationSchema = useBookingFormValidation(
    currentEvent.experienceId
  );
  const form = useForm<BookingFormData>({
    resolver: yupResolver(formValidationSchema),
  });

  useEffect(() => {
    form.reset({
      email: currentEvent.booking?.email,
      name: currentEvent.booking?.name,
      phone: currentEvent.booking?.phone,
      internalNotes: currentEvent.booking?.internalNotes,
      guests: currentEvent.booking?.guests,
      language: currentEvent.booking?.language,
      shouldSendNotification: currentEvent.booking?.shouldSendNotification,
      paymentMethod: currentEvent.booking?.paymentMethod ?? 'none',
      vatNumber: currentEvent.booking?.vatNumber,
      companyName: currentEvent.booking?.companyName,
      location: currentEvent.booking?.location,
    } as BookingFormData);
  }, [
    currentEvent.booking?.email,
    currentEvent.booking?.guests,
    currentEvent.booking?.internalNotes,
    currentEvent.booking?.language,
    currentEvent.booking?.name,
    currentEvent.booking?.phone,
    currentEvent.booking?.shouldSendNotification,
    currentEvent.booking?.vatNumber,
    currentEvent.booking?.companyName,
    currentEvent.booking?.paymentMethod,
    currentEvent.booking?.location,
    form,
  ]);

  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const handleNextStep = () => {
    updateMultipleEventFields({ ...form.getValues(), step: 'time' });
    trackEventFlowBookingStepCompleted(
      form.getValues(),
      currentEvent,
      experiences
    );
  };

  const action = (
    <Button
      size="medium"
      variant="primary"
      width={isSm ? 'auto' : 208}
      disabled={Object.keys(errors).length > 0}
      onClick={handleSubmit(() => handleNextStep())}
    >
      {t('eventUpsert.actions.nextStep')}
    </Button>
  );

  return (
    <FormProvider {...form}>
      <EventUpsertContent
        title="Booking information"
        onBack={updateEventStepLatest}
        action={action}
      >
        <EventUpsertBookingForm />
      </EventUpsertContent>
    </FormProvider>
  );
};
