import { Skeleton } from '@mui/material';
import { Stack } from '@mui/system';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import useResponsive from '../../../../Hooks/layout/useResponsive';
import { useExperience } from '../../../../Hooks/useExperience';
import { useLocalizedStringFormatter } from '../../../../Hooks/useLocalizedStringFormatter';
import { EconomicAccountAutocomplete } from './economic-account-autocomplete';

export const EconomicConfigurationExperienceAccounts: FC = () => {
  const { experiences } = useExperience();
  const localizer = useLocalizedStringFormatter();
  const { watch } = useFormContext();
  const { isSm } = useResponsive();

  if (experiences.isLoading || !experiences.data) {
    return <Skeleton variant="rounded" width={300} height={55} />;
  }

  return (
    <Stack
      gap={3}
      width="100%"
      flexDirection="row"
      flexWrap="wrap"
      pl={isSm ? 4 : 0}
    >
      {experiences.data.map((experience) => (
        <EconomicAccountAutocomplete
          key={experience.id}
          label={localizer(experience.headline)}
          path={`experienceSales.${experience.id}`}
          defaultValue={watch(`accounts.salesWithVat`)}
        />
      ))}
    </Stack>
  );
};
