import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import * as api from '../../Api';
import { useProfile } from '../../Hooks/useProfile';

export type AccumulatedMetricsItem = {
  key: string;
  value: number | undefined;
};

const getDate = (date: Date) => format(date, 'yyyy-MM-dd');

const sumMetric = (key: string, values: { key: string; value: number }[]) => {
  return values.reduce(
    (total, { value, key: k }) => (key === k ? total + value : total),
    0
  );
};

export const useMetrics = (filters: {
  from: Date;
  to: Date;
  experience: string | null;
  selectedMetric: number;
}) => {
  const { company } = useProfile();
  const { i18n } = useTranslation();

  // Include company id in the metrics query, to force re-query when company changes
  const METRICS_QUERY_KEY = ['metrics', company?.data?.id ?? '-'];

  const metrics = useQuery(
    [
      METRICS_QUERY_KEY,
      filters.experience,
      getDate(filters.from),
      getDate(filters.to),
    ],
    () => {
      return api.getMetrics(
        getDate(filters.from),
        getDate(filters.to),
        filters.experience
      );
    },
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  if (!metrics.data) {
    return {
      isLoading: metrics.isLoading || metrics.isPreviousData,
      isError: metrics.isError,
      graphSeries: [],
      accumulatedMetricsItems: [],
      metricNames: [],
    };
  }

  const { metrics: metricsData, metadata } = metrics.data;

  const metricNames = metricsData
    ? Object.values(metricsData)?.[0].map((x) => x.key)
    : [];

  const accumulatedMetricsItems = metricNames.reduce<AccumulatedMetricsItem[]>(
    (total, key) => {
      return [
        ...total,
        {
          key,
          value: sumMetric(key, Object.values(metricsData ?? {}).flat()),
        },
      ];
    },
    []
  );

  const graphSeries = Object.entries(metricsData ?? {})
    .sort((a, b) => Number(new Date(a[0])) - Number(new Date(b[0])))
    .reduce((acc, [date, values]) => {
      return {
        ...acc,
        [date]:
          values[filters.selectedMetric]?.value.toLocaleString(i18n.language) ??
          0,
      };
    }, {});

  return {
    isLoading: metrics.isLoading || metrics.isPreviousData,
    isError: metrics.isError,
    graphSeries,
    accumulatedMetricsItems,
    metricNames,
    metadata,
  };
};
