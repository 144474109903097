import NiceModal from '@ebay/nice-modal-react';
import { Button, lightTheme } from '@holdbar-com/pixel';
import {
  AddRounded,
  QrCodeScannerRounded,
  ScannerRounded,
  SearchRounded,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { memo, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useMatch, useNavigate } from 'react-router-dom';

import { ampli } from '../../Ampli';
import { useAuth } from '../../Hooks/useAuth';
import { _menuItems, useMenu } from '../../Hooks/useMenu';
import { useSearch } from '../../Hooks/useSearch';
import { TicketScannerDialog } from '../../Modals/ticket-scanner/ticket-scanner-dialog';
import { trackBookingCheckInStarted } from '../../tracking/bookings/details';
import { trackEventFlowOpened } from '../../tracking/events/flow/trackEventFlowOpened';
import { AccountPreview } from '../AccountPreview/AccountPreview';
import { LanguageSelect } from '../LanguageSelect/LanguageSelect';
import { HoldbarLogoBlack } from '../Logo/HoldbarLogoBlack';
import { ShortcutTooltip } from '../tooltip/shortcut-tooltip';
import { SuggestionsMenuItem } from './SuggestionsMenuItem';

type AppShellProps = {
  hideContainerPadding?: boolean;
  children?: ReactNode;
};

// eslint-disable-next-line react/display-name
export const AppShell = memo(
  ({ hideContainerPadding = false, children }: AppShellProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const navRef = useRef<HTMLElement | null>(null);
    const [navWidth, setNavWidth] = useState(260);
    const [mobileOpen, setMobileOpen] = useState(false);
    const isMdOrHigher = useMediaQuery('(min-width: 960px)');

    const { openSearch } = useSearch();

    const openScanner = () => {
      NiceModal.show(TicketScannerDialog);
      trackBookingCheckInStarted('qr_code');
    };

    const { featureBookingSearch, featureTicketing, featureSuggestionsCenter } =
      useFlags();

    useEffect(() => {
      const n = navRef.current?.clientWidth ?? 260;
      setNavWidth(n);
    }, []);

    const {
      params: { id: locationKey },
    } = useMatch(':id/*') ?? { params: {} };

    const { canAccess, userScopes, isAuthenticated } = useAuth();

    const { menuItems, frontPageBasedOnScopes } = useMenu();

    useEffect(() => {
      if (menuItems.length === 1) return;

      const menuItem = _menuItems.find(
        (el) =>
          locationKey === el.path ||
          el?.matches?.includes(locationKey as string)
      );

      if (!menuItem) {
        return;
      }

      if (
        isAuthenticated &&
        menuItem.scopes &&
        !menuItem.scopes.some((scope) => canAccess(scope))
      ) {
        if (!frontPageBasedOnScopes) {
          window.location.href = 'https://app.holdbar.com/login';
          return;
        }
        navigate(`/${frontPageBasedOnScopes}`, { replace: true });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userScopes, locationKey, menuItems]);

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

    return (
      <Box
        component={'main'}
        sx={{
          display: 'flex',
          width: '100%',
          height: { xs: '100%', lg: '100vh' },
          justifyContent: { xs: 'flex-start', lg: 'space-between' },
          flexDirection: { xs: 'column', lg: 'row' },

          p: { xs: '18px', sm: '30px', lg: '0' },
        }}
      >
        <AppBar
          position="fixed"
          sx={{
            boxShadow: 'none',
            display: { xs: 'flex', lg: 'none' },
            width: { lg: `calc(100% - ${navWidth}px)` },
            ml: { lg: `${navWidth}px` },
            background: '#212121',
          }}
        >
          <Toolbar
            disableGutters
            sx={{ justifyContent: 'space-between', px: 2 }}
          >
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ color: '#fff' }}
            >
              <MenuIcon fontSize="medium" />
            </IconButton>
            <Stack direction="row" gap={1}>
              {featureTicketing && (
                <IconButton
                  aria-label="ticket scanner"
                  edge="end"
                  onClick={() => {
                    openScanner();
                  }}
                  sx={{ color: '#fff' }}
                >
                  <QrCodeScannerRounded fontSize="medium" />
                </IconButton>
              )}
              {featureBookingSearch && (
                <IconButton
                  aria-label="search"
                  edge="end"
                  onClick={() => {
                    ampli.searchOpen({
                      location: window.location.pathname,
                      trigger: 'mobile button',
                    });

                    openSearch();
                  }}
                  sx={{ color: '#fff' }}
                >
                  <SearchRounded fontSize="medium" />
                </IconButton>
              )}
            </Stack>
          </Toolbar>
        </AppBar>

        {/* sidebar */}
        <Box
          component="nav"
          sx={{ width: { lg: navWidth }, flexShrink: { lg: 0 }, zIndex: 99 }}
          aria-label="sidebar"
        >
          {/* mobile */}
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={{
              display: { xs: 'block', lg: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: navWidth,
                padding: '15px',
                alignItems: 'center',
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                my: 1,
                pl: 2,
                width: '100%',
              }}
            >
              <HoldbarLogoBlack />
              <IconButton
                aria-label="close drawer"
                edge="start"
                onClick={() => setMobileOpen(false)}
                sx={{ color: '#464646' }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>

            {/* the list mobile */}
            <Stack component={List} width={'100%'} gap={1}>
              {featureBookingSearch && <NavBarActions />}
              {menuItems.map((el) => (
                <Link
                  to={`/${el.path}`}
                  onClick={() => setMobileOpen(false)}
                  key={el.path}
                >
                  <ListItem
                    data-intercom-target={`navbar-${el.path}`}
                    sx={{
                      p: 0,
                      borderRadius: 1,
                      '&:hover svg': { color: 'black' },
                    }}
                  >
                    <ListItemButton
                      sx={{
                        py: 1,
                        px: 2,
                        borderRadius: 1,
                        '&:hover': {
                          color: 'black',
                          backgroundColor: lightTheme.palette.neutral.n100,
                        },
                        '&.Mui-selected': {
                          backgroundColor: lightTheme.palette.neutral.n50,
                          border: `1px solid ${lightTheme.palette.neutral.n50}`,
                          borderRadius: 1,
                        },
                      }}
                      selected={
                        locationKey === el.path ||
                        el?.matches?.includes(locationKey as string)
                      }
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: '30px',
                          '& svg': { color: 'inherit', fontSize: '20px' },
                        }}
                      >
                        {el.icon}
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          fontSize: lightTheme.typography.small.normal.fontSize,
                        }}
                        primary={t(`utils.primaryNavigation.${el.path}`)}
                      />
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </Stack>
            <Stack marginTop={'auto'} gap={4} width={'100%'}>
              {!isMdOrHigher && <LanguageSelect />}
              <AccountPreview />
            </Stack>
          </Drawer>

          {/* desktop */}
          <Drawer
            variant="permanent"
            open
            sx={{
              display: { xs: 'none', lg: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: navWidth,
                padding: '15px',
                maxWidth: '100%',
                borderRight: `1px solid ${lightTheme.palette.neutral.n50}`,
              },
            }}
          >
            <Box m={'24px 8px 8px 8px'}>
              <HoldbarLogoBlack />
            </Box>
            {featureBookingSearch && <NavBarActions />}
            <Stack component={List} width={'100%'} gap={1}>
              {menuItems.map((el) => (
                <Link to={`/${el.path}`} key={el.path}>
                  <ListItem
                    data-intercom-target={`navbar-${el.path}`}
                    sx={{
                      p: 0,
                      borderRadius: 1,
                      '&:hover svg': { color: 'black' },
                    }}
                  >
                    <ListItemButton
                      sx={{
                        py: 1,
                        px: 2,
                        borderRadius: 1,
                        '&:hover': {
                          color: 'black',
                          backgroundColor:
                            lightTheme.palette.neutral.n100 + '80',
                        },
                        '&.Mui-selected': {
                          backgroundColor: lightTheme.palette.neutral.n50,
                          border: `1px solid ${lightTheme.palette.neutral.n50}`,
                          borderRadius: 1,
                        },
                      }}
                      selected={
                        locationKey === el.path ||
                        el?.matches?.includes(locationKey as string)
                      }
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: '30px',
                          '& svg': { color: 'inherit', fontSize: '20px' },
                        }}
                      >
                        {el.icon}
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          fontSize: lightTheme.typography.small.normal.fontSize,
                        }}
                        primary={t(`utils.primaryNavigation.${el.path}`)}
                      />
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}

              {featureSuggestionsCenter && isMdOrHigher && (
                <SuggestionsMenuItem />
              )}
            </Stack>
            <Box marginTop={'auto'}>
              <AccountPreview />
            </Box>
          </Drawer>
        </Box>

        {/* page container */}
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            ...(!hideContainerPadding && PageContainerStyles),
          }}
        >
          {children}
        </Box>
      </Box>
    );
  }
);

const NavBarActions = () => {
  const navigate = useNavigate();
  const { openSearch } = useSearch();
  const { t } = useTranslation();
  const { canAccess } = useAuth();

  const canCreateEvent = canAccess('event.write');

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={1}
      my={1}
    >
      {canCreateEvent && (
        <Box sx={{ flexGrow: 1 }}>
          <Button
            variant="secondary"
            size="medium"
            width="100%"
            style={{ padding: '16px', justifyContent: 'flex-start' }}
            leftIcon={
              <AddRounded
                fontSize="small"
                htmlColor={lightTheme.palette.neutral.n500}
                sx={{ mr: 1 }}
              />
            }
            onClick={() => {
              navigate('/event/create');
              trackEventFlowOpened('navbar', 'create');
            }}
            data-intercom-target="sidebar-create-new-event"
          >
            {t('utils.primaryNavigation.newEvent')}
          </Button>
        </Box>
      )}
      <ShortcutTooltip
        title={t('utils.primaryNavigation.search')}
        shortcut="K"
        hideTitle={!canCreateEvent}
      >
        <Button
          data-intercom-target="sidebar-open-search"
          size="medium"
          variant="secondary"
          style={{
            justifyContent: canCreateEvent ? 'center' : 'flex-start',
            gap: '8px',
            padding: '16px',
            width: canCreateEvent ? '40px' : '100%',
          }}
          leftIcon={
            canCreateEvent ? undefined : (
              <SearchRounded
                fontSize="small"
                htmlColor={lightTheme.palette.neutral.n500}
              />
            )
          }
          onClick={() => {
            ampli.searchOpen({
              location: window.location.pathname,
              trigger: 'button',
            });

            openSearch();
          }}
        >
          {canCreateEvent ? (
            <SearchRounded
              fontSize="small"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            />
          ) : (
            t('utils.primaryNavigation.search')
          )}
        </Button>
      </ShortcutTooltip>
    </Stack>
  );
};

export const PageContainerStyles = {
  pl: { xs: 0, lg: '50px' },
  pr: { xs: 0, lg: '50px' },
  pt: { xs: 5, md: 3 },
};
