import NiceModal from '@ebay/nice-modal-react';
import { lightTheme, Text } from '@holdbar-com/pixel';
import { renderDateTime } from '@holdbar-com/utils-date';
import { capitalize, styled } from '@mui/material';

import { formatMoney } from '../../../../../../../../Components/VariantSelect/VariantSelect';
import { GiftCardsListDialog } from '../../../../../../../giftcards/ui/giftcards_list/GiftCardsListDialog';
import { TPaymentRow } from '../../../../../domain/types';
import { BookingDetailsSummaryPaymentOpenLink } from '../ui/BookingDetailsSummaryPaymentOpenLink';
import { DiscountTransaction } from '@holdbar-com/utils-types';

export const paymentRows: TPaymentRow[] = [
  {
    key: 'paymentState',
    value: (_, t, receipt) => {
      if(!receipt) return undefined;

      if(receipt.transactions.length === 0){
        return t!('unpaid', 'utils.statusOptions');
      }

      const total = receipt.financials.grandTotalCents;

      if(receipt.refunds.length > 0){
        const refundedTotal = receipt.refunds.reduce((acc, refund) => acc += refund.amountRefundedCents, 0);
        if(refundedTotal === total){
          return t!('refunded', 'utils.statusOptions');
        }

        return t!('refundedPartially', 'utils.statusOptions');
      }

      const paidTotal = receipt.transactions.reduce((acc, transaction) => acc += transaction.totalAmountChargedCents, 0);
      if(paidTotal < total){
        return t!('paidPartially', 'utils.statusOptions');
      }

      return t!('paid', 'utils.statusOptions');
    },
  },
  {
    key: 'paymentLink',
    value: (el, _t, receipt, event) => {
      if (!event) {
        return null;
      }

      if(el.status === 'cancelled' || el.status === 'moved' || !receipt){
        return null;
      }

      const paidTotal = receipt.transactions.reduce((acc, transaction) => acc += transaction.totalAmountChargedCents, 0);
      
      // Already paid in full
      if(paidTotal >= receipt.financials.grandTotalCents){
        return null;
      }

      // If it's a booking through an integration, we don't want to show the payment link
      if (el.channel === 'integration') {
        return null;
      }

      return <BookingDetailsSummaryPaymentOpenLink />;
    },
  },
  {
    key: 'paymentMethod',
    value: (_, t, receipt) => {
      if (!receipt?.status) return undefined;

      const paymentMethod =
        receipt?.transaction.payment?.method === 'paypal'
          ? 'PayPal'
          : receipt?.transaction.payment?.brand
            ? `${capitalize(receipt?.transaction.payment?.brand)} (Holdbar Pay)`
            : receipt?.transaction.voucher?.code && (
                <StyledGiftCardLink
                  style={{ fontSize: 'inherit' }}
                  onClick={() =>
                    NiceModal.show(GiftCardsListDialog, {
                      voucherId: receipt?.transaction.voucher?.id,
                    })
                  }
                >
                  {t!('giftCard', 'utils.statusOptions', {
                    code: receipt?.transaction.voucher?.code,
                  })}
                </StyledGiftCardLink>
              );

      return paymentMethod;
    },
  },
  {
    key: 'refunded',
    value: (el) => {
      if (!el?.refund?.date) return undefined;

      return renderDateTime(el.refund.date);
    },
  },
  {
    key: 'receiptId',
    value: (_, t, receipt) => {
      if (!receipt?.id) return undefined;

      return receipt?.id;
    },
    showDivider: true,
  },
  {
    key: 'subtotal',
    value: (_, t, receipt) => {
      if (!receipt || !receipt?.financials.subtotalVatExclusiveCents)
        return undefined;

      return formatMoney(
        t!,
        true
      )({
        value: receipt.financials.subtotalVatExclusiveCents / 100,
        nativeCurrency: receipt.financials.currency,
      });
    },
  },
  {
    key: 'VAT',
    value: (_, t, receipt) => {
      if (!receipt || !receipt?.financials.totalVatAmountCents) return undefined;

      return formatMoney(
        t!,
        true
      )({
        value: receipt.financials.totalVatAmountCents / 100,
        nativeCurrency: receipt.transaction.currency,
      });
    },
  },
  {
    key: 'totalAmount',
    value: (_, t, receipt) => {
      if (!receipt) return undefined;

      return formatMoney(
        t!,
        true
      )({
        value: receipt.financials.subtotalVatInclusiveCents / 100,
        nativeCurrency: receipt.financials.currency,
      });
    },
  },
  {
    key: 'discount',
    value: (_, t, receipt) => {
      const discount = receipt?.metadata.discount as DiscountTransaction;

      if (!receipt || !discount) return undefined;
      // TODO: Figure out where we can get the different discounts from - possibly warrants a refactor
      return `${discount.code} ${discount.percentage ? discount.rate + '%' : ''} ${formatMoney(
        t!,
        true
      )({
        value: -(receipt.financials.totalDiscountCents ?? 0) / 100,
        nativeCurrency: receipt.financials.currency,
      })}`;
    },
  },
  {
    key: 'totalPaid',
    value: (_, t, receipt) => {
      if (!receipt) return undefined;

      return formatMoney(
        t!,
        true
      )({
        value: receipt.financials.grandTotalCents / 100,
        nativeCurrency: receipt?.financials.currency,
      });
    },
    highlight: true,
  },
];

const StyledGiftCardLink = styled(Text)`
  cursor: pointer;
  color: ${lightTheme.palette.action.a300};

  &:hover {
    text-decoration: underline;
  }
`;
