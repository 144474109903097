import { ampli } from '../../Ampli';
import {
  endDateEventProperties,
  startDateEventProperties,
} from './eventProperties';

export const trackOverviewStartDateOpened = () => {
  ampli.overviewStartDateOpened();
};

export const trackOverviewStartDateSelected = (
  new_start_date: Date,
  current_end_date: Date
) => {
  const dateEventProperties = startDateEventProperties(
    new_start_date,
    current_end_date
  );

  ampli.overviewStartDateSelected(dateEventProperties);
};

export const trackOverviewEndDateOpened = () => {
  ampli.overviewEndDateOpened();
};

export const trackOverviewEndDateSelected = (
  current_start_date: Date,
  new_end_date: Date
) => {
  const dateEventProperties = endDateEventProperties(
    current_start_date,
    new_end_date
  );

  ampli.overviewEndDateSelected(dateEventProperties);
};

export const trackOverviewMetricTileClicked = (metric_name: string) => {
  ampli.overviewMetricTileClicked({ metric_name });
};
