import { Box, Checkbox, FormControlLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import { useEffect } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { Text } from '@holdbar-com/pixel';
import { VariantSelect } from '../../../../../../../Components/VariantSelect/VariantSelect';
import useResponsive from '../../../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../../../Hooks/useTranslate';
import { useEventUpsert } from '../../../../domain/event_upsert_context';
import { EventUpsertBookingFormEmailField } from './EventUpsertBookingFormEmailField';
import { EventUpsertBookingFormLanguageSelect } from './EventUpsertBookingFormLanguageSelect';
import { EventUpsertBookingFormNameField } from './EventUpsertBookingFormNameField';
import { EventUpsertBookingFormNotesField } from './EventUpsertBookingFormNotesField';
import { EventUpsertBookingFormPhoneField } from './EventUpsertBookingFormPhoneField';
import { useTranslation } from 'react-i18next';
import { BookingFormData } from './use_booking_form_validation';
import { useProfile } from '../../../../../../../Hooks/useProfile';

export const EventUpsertBookingForm = () => {
  const { t } = useTranslate('dialogs.createBooking');
  const { isMd } = useResponsive();
  const { currentEvent, updateEventField } = useEventUpsert();
  const { company } = useProfile();

  const {
    formState: { errors },
    setValue,
    register,
  } = useFormContext();
  const guestsError = errors['guests'] as FieldError | undefined;

  useEffect(() => {
    if(!currentEvent.booking) {
      return;
    }

    if (!(currentEvent.booking?.shouldSendNotification === false)) {
      updateEventField('booking', {
        ...currentEvent.booking,
        shouldSendNotification: true,
      });
      setValue('shouldSendNotification', true);
    } else {
      updateEventField('booking', {
        ...currentEvent.booking,
        shouldSendNotification: false,
      });
      setValue('shouldSendNotification', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component="form">
      <Stack
        direction={isMd ? 'column' : 'row'}
        rowGap={3}
        columnGap={6}
        maxWidth={isMd ? '424px' : '90%'}
      >
        <Stack gap={3} flex={2} maxWidth="424px">
          <VariantSelect
              experienceId={currentEvent.experienceId}
              maxGuestCount={currentEvent?.seatCount?.value ?? 0}
              errorMessage={guestsError?.message}
              required
              onChange={(guests) => {
                setValue('guests', guests, { shouldValidate: true });
                updateEventField('booking', { ...currentEvent.booking!, guests });
              }}
            />

          <EventUpsertBookingFormLanguageSelect />

          <EventUpsertBookingFormNotesField />

        <Stack columnGap={3}>
          <Text fontSize="medium">{t('paymentMethod')}</Text>
          <PaymentMethodSelector invoicingEnabled={!!company.data?.invoiceSettings?.enabled} />
        </Stack>
        

      </Stack>
        <Box flex={1}>
          
          <Stack rowGap={3} columnGap={6}>
            <EventUpsertBookingFormNameField width='100%' />
            <EventUpsertBookingFormLocationField locationKey='address' width='100%' />
            <Stack direction="row" gap={2}>
              <EventUpsertBookingFormLocationField locationKey='zipCode' width='30%' />
              <EventUpsertBookingFormLocationField locationKey='city' width='70%' />
            </Stack>
            
              
            <Stack direction="row" gap={2}>
              <EventUpsertBookingFormEmailField />
              <EventUpsertBookingFormPhoneField />
            </Stack>

            {currentEvent.booking?.paymentMethod === 'invoice' && (
              <>
                <Stack direction="row" gap={2}>
                  <EventUpsertBookingFormCompanyNameField width='70%' />
                  <EventUpsertBookingFormVatNumberField />
                </Stack>
              </>
            )}
            <FormControlLabel
              {...register('shouldSendNotification')}
              label={t('actions.sendNotification')}
              control={
                <Checkbox
                  checked={!!currentEvent.booking?.shouldSendNotification}
                />
              }
              onChange={(_, checked) => {
                updateEventField('booking', {
                  ...currentEvent.booking!,
                  shouldSendNotification: checked,
                });
              }}
            />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

const PaymentMethodSelector = ({invoicingEnabled}: {invoicingEnabled: boolean}) => {
  const { currentEvent, updateEventField } = useEventUpsert();
  const { t } = useTranslation();
  
  const {
    control,
    formState: { errors },
  } = useFormContext<Pick<BookingFormData, 'paymentMethod'>>();

  const paymentMethods = [
    {
      key: 'paymentLink',
      label: t('booking.payment.paymentLink'),
    },
    ...(invoicingEnabled ? [{
      key: 'invoice',
      label: t('booking.payment.invoice'),
    }] : []),
    {
      key: 'none',
      label: t('booking.payment.none')
    }
  ]
  
  return (
    <Controller
      name="paymentMethod"
      control={control}
      render={({ field }) => (
    <RadioGroup
          defaultValue={'paymentLink'}
          value={currentEvent.booking?.paymentMethod || 'paymentLink'}
          onChange={(e) => {
            field.onChange(e);
            updateEventField('booking', {
              ...currentEvent.booking!,
              paymentMethod: e.target.value,
            });
          }}
          sx={{ width: 'fit-content' }}
        >
          {paymentMethods.map((method) => (
            <FormControlLabel
              key={method.key}
              value={method.key}
              control={<Radio />}
              label={method.label}
            />
          ))}
        </RadioGroup>
      )}
    />
  )
};

const EventUpsertBookingFormVatNumberField = () => {
  const { t } = useTranslation();
  const { currentEvent, updateEventField } = useEventUpsert();

  const {
    control,
    formState: { errors },
  } = useFormContext<Pick<BookingFormData, 'vatNumber'>>();

  return (
    <Controller
      name="vatNumber"
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          label={t('eventUpsert.flow.booking.form.vatNumber.label')}
          autoFocus
          value={currentEvent.booking?.vatNumber || ''}
          onChange={(e) => {
            field.onChange(e);
            updateEventField('booking', {
              ...currentEvent.booking,
              vatNumber: e.target.value,
            });
          }}
          helperText={errors.vatNumber?.message}
          error={!!errors.vatNumber}
        />
      )}
    />
  );
};

const EventUpsertBookingFormLocationField = ({locationKey, width = '100%'}: {locationKey: 'address' | 'zipCode' | 'city', width: string}) => {
  const { t } = useTranslation();
  const { currentEvent, updateEventField } = useEventUpsert();

  const {
    control,
    formState: { errors },
  } = useFormContext<Pick<BookingFormData, 'location'>>();
  return (
    <Controller
      name={`location.${locationKey}`}
      control={control}
      render={({ field }) => (
        
        <TextField
          {...field}
          label={t(`eventUpsert.flow.booking.form.${locationKey}.label`)}
          sx={{width}}
          autoFocus
          required
          value={currentEvent.booking?.location?.[locationKey] || ''}
          onChange={(e) => {
            field.onChange(e);
            updateEventField('booking', {
              ...currentEvent.booking,
              location: {
                ...currentEvent.booking?.location ?? {address: '', city: '', zipCode: '', country: ''},
                [locationKey]: e.target.value
              },
            });
          }}
          helperText={errors['location']?.[locationKey]?.message}
          error={!!errors['location']?.[locationKey]}
        />
        
      )}
    />
  );
};

export const EventUpsertBookingFormCompanyNameField = ({ width = '100%' }: {width?: string}) => {
  const { t } = useTranslation();
  const { currentEvent, updateEventField } = useEventUpsert();

  const {
    control,
    formState: { errors },
  } = useFormContext<Pick<BookingFormData, 'companyName'>>();

  return (
    <Controller
      name="companyName"
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          sx={{ width }}
          label={t('eventUpsert.flow.booking.form.companyName.label')}
          autoFocus
          value={currentEvent.booking?.companyName || ''}
          onChange={(e) => {
            field.onChange(e);
            updateEventField('booking', {
              ...currentEvent.booking,
              companyName: e.target.value,
            });
          }}
          helperText={errors.companyName?.message}
          error={!!errors.companyName}
        />
      )}
    />
  );
};
