import NiceModal from '@ebay/nice-modal-react';
import { useCallback, useMemo } from 'react';

import { initPaymentSetup } from '../../../../../Api/Api';
import { useOnBoarding } from '../../../../../Hooks/useOnBoarding';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { StripeDialog } from '../../../../../Modals/StripeDialog';
import { IntegrationObject } from '../use-integrations';

export const useStripeIntegration = (): IntegrationObject => {
  const { t } = useTranslate('settings.integrations');
  const { onBoarding } = useOnBoarding();

  const paymentStatus = useMemo(() => {
    const { steps: stripeSteps } =
      onBoarding.data?.items.find((el) => el.key === 'payment') ?? {};

    return stripeSteps?.find((el) => el.key === 'setup');
  }, [onBoarding.data]);

  const openPage = (url: string) => {
    const anchorElement = document.createElement('a');
    anchorElement.href = url;
    anchorElement.target = '_blank';
    anchorElement.click();
  };

  const handleConnectStripe = useCallback(
    (status: string) => async () => {
      if (status === 'connect') {
        NiceModal.show(StripeDialog, {
          onClick: async (accountStatus: 'new' | 'existing' | undefined) => {
            const { url } = await initPaymentSetup('stripe', accountStatus);
            openPage(url);
          },
        });
      } else {
        if (paymentStatus?.metadata?.existingAccount) {
          const { url } = await initPaymentSetup('stripe', 'existing');
          openPage(url);
        } else {
          const { url } = await initPaymentSetup('stripe', 'new');
          openPage(url);
        }
      }
    },
    [paymentStatus]
  );

  return {
    id: 'stripe',
    icon: '/integrations/stripe.png',
    title: t('payments.stripe.title'),
    description: t('payments.stripe.description'),
    extendedDescription: t('payments.stripe.extendedDescription'),
    status: paymentStatus?.metadata?.status ?? 'connect',
    buttonLabel: (status: string) => t(`payments.status.${status}`),
    onInstall: handleConnectStripe,
  };
};
