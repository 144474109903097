import { toast } from 'react-toastify';

import { sendSlackNotification } from '../../../../Api/Notifications';
import { useProfile } from '../../../../Hooks/useProfile';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { useGrowthOpportunityRequestStore } from '../../../../Store/useGrowthOpportunityRequestsStore';
import { Opportunity } from './types';

export const useOpportunityInterest = (opportunity: Opportunity) => {
  const { t } = useTranslate('opportunities.request');

  const {
    me: { data: me },
    company: { data: company },
  } = useProfile();

  const handleInterest = async () => {
    try {
      toast.loading(t('loading'), { toastId: 'requesting' });

      const message = `Growth Opportunity requested:\n>Opportunity: ${opportunity.title}\n>Name: ${me?.name}\n>Email: ${me?.email}\n>Company: ${company?.name}`;
      await sendSlackNotification(message, '#growth-opportunity-requests');

      toast.dismiss('requesting');
      toast.success(t('success'));

      useGrowthOpportunityRequestStore
        .getState()
        .addGrowthOpportunityRequest(opportunity.id);
    } catch {
      toast.dismiss('requesting');
      toast.error(t('error'));
    }
  };

  return { handleInterest };
};
