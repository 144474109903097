import styled from '@emotion/styled';
import { BoxProps, Button, Menu, MenuProps, Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import react, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LANGUAGES_PREFERENCES_KEY } from '../../Hooks/useLocale';
import { useProfile } from '../../Hooks/useProfile';
import { languagesOptionValues } from '../../Utils/config';

type TLanguage = { label: string; icon: string };

export const LanguageSelect = ({
  isWhite,
  items = languagesOptionValues,
  shouldSetTranslation = true,
  onChange,
  renderComp,
}: {
  isWhite?: boolean;
  items?: { [k: string]: TLanguage };
  shouldSetTranslation?: boolean;
  renderComp?: (onClick: BoxProps['onClick']) => ReactNode;
  onChange?: (key: string) => void;
}) => {
  const { t, i18n } = useTranslation();

  const [_language] = i18n.language.split('-');

  const { preferences, updatePreferences } = useProfile();

  const [selected, setSelected] = useState(
    preferences?.[LANGUAGES_PREFERENCES_KEY]?.defaultLanguage ?? _language
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: react.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (key: string) => () => {
    setSelected(key);
    if (shouldSetTranslation) {
      updatePreferences(
        LANGUAGES_PREFERENCES_KEY,
        (previous: Record<string, string> | undefined) => ({
          ...previous,
          defaultLanguage: key,
        })
      );
      i18n.changeLanguage(key);
    }
    onChange?.(key);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {renderComp ? (
        renderComp(handleClick)
      ) : (
        <Tooltip title={t('utils.generic.selectLanguage')}>
          <Button
            sx={{
              backgroundColor: isWhite ? 'white' : 'auto',
              borderRadius: 100,
              color: isWhite ? 'black' : 'grey.800',
              p: '8px 24px',
              borderColor: '#C7C7CC',
              boxShadow: '0px 4px 16px 0px rgba(0,0,0,0.08)',
              borderWidth: 1,
              textTransform: 'capitalize',
              ':hover': { backgroundColor: isWhite ? 'white' : 'auto' },
            }}
            variant={'outlined'}
            onClick={handleClick}
            startIcon={
              <Avatar
                imgProps={{ sx: { width: 'auto' } }}
                sx={{ height: 24, width: 'auto', borderRadius: 0 }}
                src={items[selected]?.icon}
              />
            }
          >
            {t(`utils.languages.${selected}`)}
          </Button>
        </Tooltip>
      )}
      <StyledMenu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {Object.entries(items).map(([key, lang]) => {
          return (
            <MenuItem
              key={key}
              selected={key === selected}
              onClick={handleSelect(key)}
              sx={{ textTransform: 'capitalize' }}
            >
              <Avatar sx={{ borderRadius: 0 }} src={lang.icon} />{' '}
              {t(`utils.languages.${key}`) ?? lang.label}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </>
  );
};

const StyledMUIButton = styled(Menu)(() => ({
  ['&.MuiButton-root']: {
    boxShadow: '0px 4px 16px 0px rgba(0,0,0,0.5)',
    borderColor: 'red',
  },
}));

const StyledMenu = ({ children, ...props }: MenuProps) => {
  return (
    <StyledMUIButton
      sx={{
        boxShadow: '0px 4px 16px 0px rgba(0,0,0,0.5)',
        borderColor: 'red',
      }}
      PaperProps={{
        sx: {
          overflow: 'hidden',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      {...props}
    >
      {children}
    </StyledMUIButton>
  );
};
