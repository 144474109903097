import { lightTheme, LinkButton, Text } from '@holdbar-com/pixel';
import { renderDate } from '@holdbar-com/utils-date';
import {
  CompanyLocation,
  ConnectedItem,
  Connection,
  Experience,
} from '@holdbar-com/utils-types';
import { ArrowBackRounded } from '@mui/icons-material';
import { Box, Chip, Stack, styled } from '@mui/material';
import { differenceInDays, startOfDay } from 'date-fns';
import React, { useEffect } from 'react';
import { LoaderFunctionArgs, useHref, useLoaderData } from 'react-router';
import { Link, Outlet, useLinkClickHandler } from 'react-router-dom';

import { getConnectionItem, getExperiences } from '../../../Api';
import {
  getPublicCompanyProfile,
  getPublicUserProfile,
} from '../../../Api/Profiles';
import { AppShell } from '../../../Components/AppShell/AppShell';
import { StatusBadge } from '../../../Components/badge/status-badge';
import { CopyLink } from '../../../Components/CopyLink/CopyLink';
import { PageBreadcrumb } from '../../../Components/Page/page_breadcrumb';
import { PageBreadcrumbItem } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/page-breadcrumb-item';
import { PageBreadcrumbBreadcrumbs } from '../../../Components/Page/page_breadcrumb/ui/page_breadcrumb_breadcrumbs/PageBreadcrumbBreadcrumbs';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { useLocalizedStringFormatter } from '../../../Hooks/useLocalizedStringFormatter';
import { useTranslate } from '../../../Hooks/useTranslate';
import {
  trackConnectDetailsViewed,
  trackConnectEmailCopied,
} from '../../../tracking/connect/connect-events';
import { decodeConnectionPath } from '../list/connection-path-tools';
import { ContextMenu } from './context-menu';
import { ResponsiveAccordion } from './shared';

export type ConnectionDetailsLoaderData = {
  connectedCompany: {
    id: string;
    name: string;
    location?: CompanyLocation;
    email: string;
    phone: string;
  } | null;
  receiverEmail: string;
  connectedCompanyLabel: string;
  createdBy?: string;
  createdAt: string;
  state: Connection['state'];
  youShareLabel: 'all' | 'none' | 'selection' | null;
  theyShareLabel: 'all' | 'none' | 'selection' | null;
  yourSharedExperiences: ConnectedItem['sharedExperiences'];
  theirSharedExperiences:
    | Pick<Experience, 'id' | 'headline' | 'status'>[]
    | null;
  token?: string;
};

function sharedExperienceConfigString(connection: Connection) {
  if ('sharedExperiences' in connection) {
    return Array.isArray(connection.sharedExperiences)
      ? 'selection'
      : connection.sharedExperiences;
  }

  return null;
}

async function listSharedExperiences(
  connectionItem: Connection
): Promise<Pick<Experience, 'id' | 'headline' | 'status'>[]> {
  const allSharedExperiences = await getExperiences({
    type: 'shared',
  });

  return allSharedExperiences
    .filter(
      (x) =>
        'ownerCompanyId' in x && x.ownerCompanyId === connectionItem.companyId
    )
    .map((x) => ({
      id: x.id,
      headline: x.headline,
      status: x.status,
    }));
}

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<ConnectionDetailsLoaderData> {
  const { companyId, receiverEmail } = decodeConnectionPath(params.id!);

  if (!companyId || !receiverEmail) {
    throw new Response('', {
      status: 404,
    });
  }

  const connection = await getConnectionItem(companyId, receiverEmail);
  if (!connection) {
    throw new Response('', {
      status: 404,
    });
  }

  const matchingItem = connection.connectedCompanyId
    ? await getConnectionItem(connection.connectedCompanyId, receiverEmail)
    : null;

  const [connectedCompany, createdByUser] = await Promise.all([
    connection.connectedCompanyId
      ? getPublicCompanyProfile(connection.connectedCompanyId)
      : null,
    getPublicUserProfile(connection.createdByUserId),
  ]);

  return {
    connectedCompany: connectedCompany
      ? {
          id: connectedCompany.id,
          name: connectedCompany.name,
          location: connectedCompany.location,
          email: connectedCompany.companyEmail,
          phone: connectedCompany.companyPhone,
        }
      : null,
    receiverEmail: connection.receiverEmail,
    connectedCompanyLabel: connectedCompany
      ? connectedCompany.name
      : receiverEmail,
    createdBy: createdByUser?.name,
    createdAt: connection.createdAt,
    state: connection.state,
    youShareLabel: sharedExperienceConfigString(connection),
    theyShareLabel: matchingItem && sharedExperienceConfigString(matchingItem),
    yourSharedExperiences:
      'sharedExperiences' in connection ? connection.sharedExperiences : [],
    theirSharedExperiences:
      matchingItem && matchingItem.state === 'connected'
        ? await listSharedExperiences(matchingItem)
        : null,
    token: 'token' in connection ? connection.token : undefined,
  };
}

export function ConnectionDetailsPage() {
  const loaderData = useLoaderData() as ConnectionDetailsLoaderData;
  const { t } = useTranslate('connect.details');
  const { t: tCountries } = useTranslate('countryCodes');
  const { t: tUtils } = useTranslate('utils');
  const { t: tSettings } = useTranslate('settings');
  const editHref = useHref(`edit`);
  const editLinkClicked = useLinkClickHandler(editHref);
  const disconnectHref = useHref(`disconnect`);
  const disconnectLinkClicked = useLinkClickHandler(disconnectHref);
  const { isMd } = useResponsive();
  const localizeString = useLocalizedStringFormatter();
  const fireOnce = useFireOnce();

  useEffect(() => {
    fireOnce(() =>
      trackConnectDetailsViewed(
        loaderData.connectedCompany?.name ?? '',
        differenceInDays(
          startOfDay(new Date()),
          startOfDay(new Date(loaderData.createdAt))
        ),
        loaderData.state
      )
    );
  }, [
    fireOnce,
    loaderData.connectedCompany?.name,
    loaderData.createdAt,
    loaderData.state,
  ]);

  return (
    <AppShell hideContainerPadding>
      <PageBreadcrumbBreadcrumbs>
        <PageBreadcrumbItem title={tSettings('title')} href="/settings" />
        <PageBreadcrumbItem
          title={tSettings('navigation.connect')}
          href="/settings/connect"
        />
        <PageBreadcrumbItem
          title={loaderData.connectedCompanyLabel}
          href="/settings/connect"
        />
      </PageBreadcrumbBreadcrumbs>
      <PageBreadcrumb>
        <Stack gap={4} width={'100%'}>
          <Stack gap={{ xs: 2, lg: 0 }} pb={2}>
            <Stack
              direction={'row'}
              justifyContent="space-between"
              alignItems={{ xs: 'start', md: 'center' }}
              gap={1}
            >
              <Stack
                gap={{ xs: 0, md: 2 }}
                direction={{ xs: 'column', md: 'row' }}
              >
                <Link to="../.." relative="path">
                  <StyledBackArrowRounded />
                </Link>
                <Stack gap={1}>
                  <Stack direction="row" gap={2} alignItems={'center'} mt={1}>
                    <Text fontSize="large" variant="medium">
                      {loaderData.connectedCompanyLabel}
                    </Text>
                    <StatusBadge
                      type="connection"
                      size="medium"
                      state={loaderData.state}
                    />
                  </Stack>
                </Stack>
              </Stack>
              {loaderData.state !== 'disconnected' &&
                (isMd ? (
                  <ContextMenu
                    actions={[
                      {
                        label: t('disconnect'),
                        href: disconnectHref,
                      },
                      {
                        label: t('edit'),
                        href: editHref,
                      },
                    ]}
                  />
                ) : (
                  <Stack direction={'row'} gap={1}>
                    <LinkButton
                      size={'small'}
                      variant={'secondary'}
                      style={{
                        color: lightTheme.palette.error.e300,
                      }}
                      href={disconnectHref}
                      onClick={disconnectLinkClicked}
                    >
                      {t('disconnect')}
                    </LinkButton>
                    <LinkButton
                      size={'small'}
                      variant={'secondary'}
                      href={editHref}
                      onClick={editLinkClicked}
                    >
                      {t('edit')}
                    </LinkButton>
                  </Stack>
                ))}
            </Stack>
          </Stack>

          <Box
            display="grid"
            gridTemplateColumns={{ xs: 'auto', md: '2fr 1fr' }}
            gridTemplateAreas={{
              xs: '"details" "contact" "distribution"',
              md: '"details contact" "distribution contact"',
            }}
            gap={3}
          >
            <ResponsiveAccordion
              title={t('details')}
              sx={{ gridArea: 'details' }}
            >
              <Stack gap={2}>
                <Box
                  display="grid"
                  gridTemplateColumns={{
                    sm: '1fr 1fr',
                  }}
                >
                  <Text> {t('connectionDate')}</Text>
                  <Text variant="medium">
                    {renderDate(loaderData.createdAt)}
                  </Text>
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns={{
                    sm: '1fr 1fr',
                  }}
                >
                  <Text> {t('createdBy')}</Text>
                  <Text variant="medium">{loaderData.createdBy}</Text>
                </Box>
                {loaderData.youShareLabel && (
                  <Box
                    display="grid"
                    gridTemplateColumns={{
                      sm: '1fr 1fr',
                    }}
                  >
                    <Text> {t('youShareLabel')}</Text>
                    <Text variant="medium">
                      {t(`youShare.${loaderData.youShareLabel}`)}
                    </Text>
                  </Box>
                )}
                {loaderData.theyShareLabel && (
                  <Box
                    display="grid"
                    gridTemplateColumns={{
                      sm: '1fr 1fr',
                    }}
                  >
                    <Text> {t('theyShareLabel')}</Text>
                    <Text variant="medium">
                      {t(`theyShare.${loaderData.theyShareLabel}`)}
                    </Text>
                  </Box>
                )}
              </Stack>
            </ResponsiveAccordion>
            {loaderData.connectedCompany ? (
              <Box sx={{ gridArea: 'contact' }}>
                <ResponsiveAccordion title={t('contactInformation')}>
                  <Stack gap={2}>
                    <Stack gap={0.5}>
                      <Text variant="medium">
                        {loaderData.connectedCompanyLabel}
                      </Text>
                      {loaderData.connectedCompany.location ? (
                        <Stack gap={1} component={Text} variant="normal">
                          {loaderData.connectedCompany.location.address && (
                            <span>
                              {loaderData.connectedCompany.location.address}
                            </span>
                          )}
                          {(loaderData.connectedCompany.location.zipCode ||
                            loaderData.connectedCompany.location.city) && (
                            <span>{`${loaderData.connectedCompany.location.zipCode} ${loaderData.connectedCompany.location.city}`}</span>
                          )}
                          {loaderData.connectedCompany.location.country && (
                            <span>
                              {tCountries(
                                loaderData.connectedCompany.location.country
                              )}
                            </span>
                          )}
                        </Stack>
                      ) : null}
                    </Stack>
                    <Stack gap={0.5}>
                      <Text variant="normal">
                        {loaderData.connectedCompany.email ? (
                          <>
                            <CopyLink
                              href={`mailto:${loaderData.connectedCompany.email}`}
                              isEmail
                              fontSize="medium"
                              label={loaderData.connectedCompany.email}
                              onClickText={() =>
                                trackConnectEmailCopied(
                                  loaderData.connectedCompanyLabel
                                )
                              }
                              onClickCopy={() =>
                                trackConnectEmailCopied(
                                  loaderData.connectedCompanyLabel
                                )
                              }
                            />
                            <br />
                          </>
                        ) : null}
                        {loaderData.connectedCompany?.phone}
                      </Text>
                    </Stack>
                  </Stack>
                </ResponsiveAccordion>
              </Box>
            ) : null}
            {loaderData.theirSharedExperiences ? (
              <ResponsiveAccordion
                title={t('distribution')}
                sx={{ gridArea: 'distribution' }}
                defaultExpanded
              >
                <Stack>
                  {loaderData.theyShareLabel === 'none' ||
                  loaderData.theirSharedExperiences.length === 0 ? (
                    <Text variant="normal">
                      {t('noExperiencesSharedWithYou', {
                        accountName: loaderData.connectedCompanyLabel,
                      })}
                    </Text>
                  ) : (
                    <Box
                      display="grid"
                      gap={2}
                      gridTemplateColumns={{
                        sm: '1fr 1fr',
                      }}
                    >
                      {loaderData.theirSharedExperiences.map((experience) => (
                        <React.Fragment key={experience.id}>
                          <Text>{localizeString(experience.headline)}</Text>

                          <Stack
                            component="span"
                            gap={1}
                            direction="row"
                            alignItems={'center'}
                          >
                            <Chip
                              color={
                                experience.status === 'active'
                                  ? 'success'
                                  : 'warning'
                              }
                              sx={{
                                height: 10,
                                width: 10,
                                borderRadius: 12,
                              }}
                            />
                            <Text
                              variant={'medium'}
                              color={
                                experience.status === 'active'
                                  ? lightTheme.palette.neutral.n500
                                  : lightTheme.palette.neutral.n400
                              }
                            >
                              {tUtils(`statusOptions.${experience.status}`)}
                            </Text>
                          </Stack>
                        </React.Fragment>
                      ))}
                    </Box>
                  )}
                </Stack>
              </ResponsiveAccordion>
            ) : null}
          </Box>
        </Stack>
      </PageBreadcrumb>
      <Outlet />
    </AppShell>
  );
}

const StyledBackArrowRounded = styled(ArrowBackRounded)({
  height: '33px',
  width: '33px',
  padding: '4px',
  cursor: 'pointer',
  borderRadius: '50%',
  marginTop: '3.5px',
  transition: 'background-color 0.1s',
  '&:hover': {
    backgroundColor: lightTheme.palette.neutral.n100,
  },
  '&:active': {
    backgroundColor: lightTheme.palette.neutral.n200,
  },
});
