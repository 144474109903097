import { Experience } from '@holdbar-com/utils-types';
import { captureException } from '@sentry/react';
import randomBytes from 'randombytes';
import { useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import {
  ActionFunctionArgs,
  defer,
  LoaderFunction,
  redirect,
  useLoaderData,
  useNavigate,
} from 'react-router';
import { useSubmit } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  createConnection,
  getCompanyProfile,
  getExperiences,
  getMyProfile,
} from '../../../Api';
import { sendSlackNotification } from '../../../Api/Notifications';
import useResponsive from '../../../Hooks/layout/useResponsive';
import { useFireOnce } from '../../../Hooks/useFireOnce';
import { useTranslate } from '../../../Hooks/useTranslate';
import { t } from '../../../i18n/config';
import {
  trackInviteNewConnectionCompleted,
  trackInviteNewConnectionStarted,
} from '../../../tracking/connect/connect-events';
import { DialogWrapper } from '../components/dialog-wrapper';
import {
  AddConnectionForm,
  AddConnectionFormInputs,
} from './add-connection-form';

type LoaderData = {
  experiences: Promise<Experience[]>;
};

export const loader: LoaderFunction<LoaderData> = async () => {
  // Intentionally not awaited. Returned as a promise to defer loading to after render.
  const experiences = getExperiences({
    type: 'owned',
  });
  return defer({ experiences });
};

export async function action({ request }: ActionFunctionArgs) {
  const loadingToastId = randomBytes(16).toString('hex');
  toast.loading(t('connect.dialog.addConnection.toast.loading'), {
    toastId: loadingToastId,
  });

  try {
    const { email, share, experiences } =
      (await request.json()) as AddConnectionFormInputs;

    await createConnection({
      email: email.trim(),
      sharedExperiences: share === 'selection' ? experiences : share,
    });

    try {
      const [userProfile, companyProfile] = await Promise.all([
        getMyProfile(),
        getCompanyProfile(),
      ]);

      const message = `New Connect request made:
>Created by: ${userProfile.name} (${userProfile.email})
>Company: ${companyProfile.name}
>Company ID: ${companyProfile.id}
>Sent to: ${email.trim()}
`;
      await sendSlackNotification(message, '#product-connect-requests');
    } catch (error) {
      captureException(error, {
        tags: {
          source: 'add-connection-dialog',
          action: 'create connection',
        },
      });
    }

    toast.dismiss(loadingToastId);
    toast.success(t('connect.dialog.addConnection.toast.success'), {
      autoClose: 5000,
    });

    const shareableExperiences = await getExperiences({ type: 'owned' });
    const sharedCount =
      share === 'all'
        ? shareableExperiences.length
        : share === 'selection'
          ? experiences.length
          : 0; // 'none' = 0

    trackInviteNewConnectionCompleted(
      share,
      shareableExperiences.length,
      sharedCount
    );

    return redirect('../');
  } catch (error) {
    console.error(error);
    toast.dismiss(loadingToastId);
    toast.error(t('connect.dialog.addConnection.toast.error'), { delay: 500 });
    return {
      success: false,
    };
  }
}

export const AddConnectionDialog = () => {
  const { t } = useTranslate('connect.dialog.addConnection');
  const { isSm } = useResponsive();
  const navigate = useNavigate();
  const submit = useSubmit();
  const { experiences } = useLoaderData() as LoaderData;
  const fireOnce = useFireOnce();

  useEffect(() => {
    fireOnce(() => trackInviteNewConnectionStarted());
  }, [fireOnce]);

  const handleClose = () => {
    navigate('../');
  };

  const onSubmit = (data: FieldValues) => {
    submit(data, {
      method: 'post',
      action: '',
      encType: 'application/json',
    });
  };

  return (
    <DialogWrapper
      fullWidth
      maxWidth="sm"
      fullScreen={isSm}
      open={true}
      onClose={handleClose}
      title={t('title')}
    >
      <AddConnectionForm onSubmit={onSubmit} experiences={experiences} />
    </DialogWrapper>
  );
};
