import { Receipt } from '@holdbar-com/utils-types';

import api from './index';

export const getReceipt = async (id: string) => {
  if (typeof process.env.REACT_APP_INTERNAL_API_GATEWAY === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_INTERNAL_API_GATEWAY is not defined'
    );
  }

  const { data } = await api.get<Receipt>(
    `${process.env.REACT_APP_INTERNAL_API_GATEWAY}/v1/orders/${id}`
  );

  return data;
};
