import { handleApiError } from '../types/api/error';
import { ILocationView } from '../types/api/location';
import api from './index';

const REACT_APP_API_LOCATIONS_URL = process.env.REACT_APP_API_LOCATIONS_URL;

export const getLocations = async (companyId: string) => {
  try {
    if (typeof REACT_APP_API_LOCATIONS_URL === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_LOCATIONS_URL is not defined'
      );
    }

    const { data } = await api.get(
      `${REACT_APP_API_LOCATIONS_URL}/locations?companyId=${companyId}`
    );

    return data.items;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getLocationsByExperienceId = async (experienceId: string) => {
  try {
    if (typeof REACT_APP_API_LOCATIONS_URL === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_LOCATIONS_URL is not defined'
      );
    }

    const { data } = await api.get(
      `${REACT_APP_API_LOCATIONS_URL}/locations?experienceId=${experienceId}`
    );

    return data.items;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getLocation = async (locationId: string) => {
  try {
    if (typeof REACT_APP_API_LOCATIONS_URL === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_LOCATIONS_URL is not defined'
      );
    }

    const { data } = await api.get<{ item: ILocationView }>(
      `${REACT_APP_API_LOCATIONS_URL}/locations/${locationId}`
    );

    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const createLocation = async (location: ILocationView) => {
  try {
    if (typeof process.env.REACT_APP_API_LOCATIONS_URL === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_LOCATIONS_URL is not defined'
      );
    }

    const { data } = await api.post<ILocationView>(
      `${process.env.REACT_APP_API_LOCATIONS_URL}/locations`,
      location
    );

    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const updateLocation = async (location: ILocationView) => {
  try {
    if (typeof process.env.REACT_APP_API_LOCATIONS_URL === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_LOCATIONS_URL is not defined'
      );
    }

    const { data } = await api.put<ILocationView>(
      `${process.env.REACT_APP_API_LOCATIONS_URL}/locations/${location.locationId}`,
      location
    );

    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const deleteLocation = async (locationId: string) => {
  try {
    if (typeof process.env.REACT_APP_API_LOCATIONS_URL === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_LOCATIONS_URL is not defined'
      );
    }

    const { data } = await api.delete(
      `${process.env.REACT_APP_API_LOCATIONS_URL}/locations/${locationId}`
    );

    return data;
  } catch (error) {
    throw handleApiError(error);
  }
};
