import api from './index';

export type Domain = {
  type: 'custom' | 'subdomain';
  domain: string;
};

export const domainAvailable = async (domain: string) => {
  domain = domain.toLowerCase();

  if (typeof process.env.REACT_APP_API_DOMAINS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_DOMAINS_URL is not defined'
    );
  }

  const { data } = await api.get<{ available: boolean }>(
    `${process.env.REACT_APP_API_DOMAINS_URL}/${domain}/available`
  );

  return data;
};

export const createDomain = async (payload: Domain) => {
  payload.domain = payload.domain.toLowerCase();

  if (typeof process.env.REACT_APP_API_DOMAINS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_DOMAINS_URL is not defined'
    );
  }

  await api.post(`${process.env.REACT_APP_API_DOMAINS_URL}`, { ...payload });
};

export const deleteDomain = async (domain: string) => {
  domain = domain.toLowerCase();

  if (typeof process.env.REACT_APP_API_DOMAINS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_DOMAINS_URL is not defined'
    );
  }

  await api.delete(`${process.env.REACT_APP_API_DOMAINS_URL}/${domain}`);
};

export const getCompanyDomain = async (companyId: string) => {
  if (typeof process.env.REACT_APP_API_DOMAINS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_DOMAINS_URL is not defined'
    );
  }

  const url = `${process.env.REACT_APP_API_DOMAINS_URL}/companies/${companyId}/domain`;

  const response = await api.get<{
    domain: string;
    type: 'subdomain' | 'custom';
  }>(url);

  if (response.status !== 200) {
    throw new Error('Failed to get company domain');
  }

  return response.data.domain;
};
