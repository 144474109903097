import { UserProfile } from '@holdbar-com/utils-types';
import api, { authApi, IShallowSuccessResponse } from './index';

export interface TokenResponse {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
  orgs: {
    id: string;
    name: string;
    logo?: string;
  }[];
}

interface ApiError {
  error: string;
}

interface SignInError {
  message: string;
}

export const signIn = async (username: string, password: string) => {
  const url = process.env.REACT_APP_API_ACCOUNTS_URL;

  if (typeof url === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }

  try {
    const response = await authApi.post<TokenResponse | SignInError>(
      `${url}/oauth/token`,
      {
        username,
        password,
        grant: 'password',
      }
    );

    return response.data as TokenResponse;
  } catch (err) {
    const serverError = err as { response: { data: SignInError } };
    if (!serverError) {
      throw new Error('No response from server');
    }
    throw new Error(serverError.response.data.message);
  }
};

export const refreshToken = async (refreshToken: string, org?: string) => {
  const url = process.env.REACT_APP_API_ACCOUNTS_URL;

  if (typeof url === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }

  const { data } = await authApi.post<TokenResponse>(`${url}/oauth/token`, {
    refresh_token: refreshToken,
    org,
    grant: 'refresh_token',
  });

  return data;
};

export const signOut = async () => {
  const url = process.env.REACT_APP_API_ACCOUNTS_URL;

  if (typeof url === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }

  const { data } = await authApi.post<IShallowSuccessResponse>(`${url}`);

  return data;
};

export const createAccount = async (email: string, password: string) => {
  try {
    const url = process.env.REACT_APP_API_ACCOUNTS_URL;

    if (typeof url === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
      );
    }

    const response = await authApi.post<TokenResponse>(`${url}/users`, {
      password,
      claims: { email },
    });

    return response.data;
  } catch (err: any) {
    const authError = err as { response: { data: ApiError } };
    if (!authError) {
      throw new Error('SERVER_ERROR');
    }
    throw new Error(authError.response.data.error);
  }
};

/**
 * Add account to company. This only works for preview companies.
 * @param userId
 * @param companyId
 * @param role
 * @param name
 * @returns
 */
export const addAccountToPreviewCompany = async (
  userId: string,
  companyId: string,
  role: 'admin' | 'manager' | 'guide',
  name: string = ''
) => {
  try {
    const url = process.env.REACT_APP_API_ACCOUNTS_URL;

    if (typeof url !== 'string') {
      throw new Error(
        'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
      );
    }

    const signupType: NonNullable<UserProfile['metadata']>['signupType'] =
      'AI-signup';

    const response = await api.post<TokenResponse>(
      `${url}/companies/${companyId}/users`,
      {
        userId,
        role,
        name,
        signupType,
      }
    );

    return response.data;
  } catch (err: any) {
    const authError = err as { response: { data: ApiError } };
    if (!authError) {
      throw new Error('SERVER_ERROR');
    }
    throw new Error(authError.response.data.error);
  }
};

export const userExists = async (email: string, invitationId: string) => {
  const url = process.env.REACT_APP_API_ACCOUNTS_URL;

  if (typeof url === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }

  const { status } = await authApi.get(
    `${url}/users?email=${email}&invitationId=${invitationId}`
  );

  return status === 204;
};

export const acceptInvitation = async (
  email: string,
  invitationId: string,
  password?: string
) => {
  try {
    const url = process.env.REACT_APP_API_ACCOUNTS_URL;

    if (typeof url === 'undefined') {
      throw new Error(
        'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
      );
    }

    const { data } = await authApi.post<TokenResponse>(
      `${url}/invitations/${invitationId}/accept`,
      {
        password,
        claims: { email },
      }
    );

    return data;
  } catch (err) {
    const authError = err as { response: { data: { error: string } } };
    if (!authError) {
      throw new Error('SERVER_ERROR');
    }
    throw new Error(authError.response.data.error);
  }
};

export const resetPassword = async (email: string, language: string = 'da') => {
  const url = process.env.REACT_APP_API_ACCOUNTS_URL;

  if (typeof url === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }

  const { data } = await authApi.post(`${url}/reset`, {
    username: email,
    language,
  });

  return data;
};

export const updateResatPassword = async (
  email: string,
  password: string,
  id: string,
  token: string
) => {
  const url = process.env.REACT_APP_API_ACCOUNTS_URL;

  if (typeof url === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_ACCOUNTS_URL is not defined'
    );
  }

  const { data } = await authApi.put<TokenResponse>(
    `${url}/users/${id}`,
    {
      password,
      claims: { email },
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};
