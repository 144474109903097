import { MenuItem, Select, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useTranslate } from '../../../../Hooks/useTranslate';
import { CreateBookingFormInputs } from '../create-booking-form';

type CustomerInformationProps = {
  payment: string;
};

export const CustomerInformation: FC<CustomerInformationProps> = ({
  payment,
}) => {
  const { t } = useTranslate('dialogs.createBooking');

  const {
    register,
    formState: { errors },
  } = useFormContext<CreateBookingFormInputs>();

  return (
    <Stack gap={3}>
      <TextField
        {...register('customer.name', {
          required: t('required', 'utils.errors'),
        })}
        label={t('name', 'utils.generic')}
        fullWidth
        error={Boolean(errors.customer?.name?.message)}
        helperText={errors.customer?.name?.message}
        required
      />
      <TextField
        {...register('customer.location.address')}
        label={t('address', 'utils.generic')}
        fullWidth
        error={Boolean(errors.customer?.location?.address?.message)}
        helperText={errors.customer?.location?.address?.message}
      />
      <Stack direction="row" spacing={2}>
        <TextField
          {...register('customer.location.zipCode')}
          label={t('zipCode', 'utils.generic')}
          sx={{width: '30%'}}
          error={Boolean(errors.customer?.location?.zipCode?.message)}
          helperText={errors.customer?.location?.zipCode?.message}
        />

        <TextField
          {...register('customer.location.city')}
          label={t('city', 'utils.generic')}
          sx={{width: '70%'}}
          error={Boolean(errors.customer?.location?.city?.message)}
          helperText={errors.customer?.location?.city?.message}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField
          {...register('customer.email', {
            required: t('required', 'utils.errors'),
            pattern: {
              value: /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: t('invalidEmail', 'auth.errors'),
            },
          })}
          type="email"
          label={t('email', 'utils.generic')}
          sx={{width: '60%'}}
          error={Boolean(errors.customer?.email?.message)}
          helperText={errors.customer?.email?.message}
          required
        />
        <TextField
          {...register('customer.phone')}
          label={t('phone', 'utils.generic')}
          sx={{width: '40%'}}
        />
      </Stack>

      {payment === 'invoice' && (<>
      <Stack direction="row" spacing={2}>
        <TextField
          {...register('customer.companyName')}
          label={t('companyName', 'utils.generic')}
          sx={{width: '60%'}}
          error={Boolean(errors.customer?.companyName?.message)}
          helperText={errors.customer?.companyName?.message}
        />

        <TextField
          {...register('customer.vatNumber')}
          label={t('vatNumber', 'utils.generic')}
          sx={{width: '40%'}}
          error={Boolean(errors.customer?.vatNumber?.message)}
          helperText={errors.customer?.vatNumber?.message}
        />
      </Stack></>)}
    </Stack>
  );
};
