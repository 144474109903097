import { Box } from '@mui/material';

import { TranslateFunction } from '../../Hooks/useTranslate';
import { StatsCard } from './StatsCard';

type Props = {
  items: {
    key: string;
    value: string | undefined;
    allowedOnMobile: boolean;
    featured: boolean;
  }[];
  t: TranslateFunction;
  defaultCurrency: any;
  selectedMetric: number;
  setSelectedMetric: any;
  isLoading: boolean;
};

const StatsLayout = ({
  items,
  t,
  defaultCurrency,
  selectedMetric,
  setSelectedMetric,
  isLoading,
}: Props) => {
  const featuredItem = items.find(
    (item) => item.allowedOnMobile && item.featured
  );
  const otherItems = items.filter(
    (item) => item.allowedOnMobile && !item.featured
  );

  const statsItems = featuredItem ? [featuredItem, ...otherItems] : items;

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gap: 1.2,
        width: '100%',
      }}
    >
      {statsItems.map(({ value, ...el }, i) => (
        <Box
          key={el.key}
          sx={{
            gridColumn: el.featured ? '1' : '',
            gridRow: el.featured ? '1/3' : '',
          }}
        >
          <StatsCard
            title={
              t(`statistics.cards.${el.key}`) +
              (el.key !== 'impressions' ? '*' : '')
            }
            // Check if string exists in POEditor by checking translation key
            tooltip={t(`statistics.cards.tooltips.${el.key}`, undefined, {
              defaultValue: '',
            })}
            value={
              ['revenue', 'netRevenue', 'refunds'].includes(el.key)
                ? `${value} ${t(defaultCurrency, 'utils.currency')}`
                : value
            }
            isSelected={selectedMetric === i && !isLoading}
            onClick={() => setSelectedMetric(i)}
            sx={{
              justifyContent: { xs: 'flex-start', md: 'space-between' },
              minHeight: '84px',
              px: 2,
              py: 1,
              fontSize: '12px',
              '& > h5': {
                m: 0,
                fontSize: el.featured ? '14px' : '12px',
              },
              '& > div > h2': {
                fontSize: '18px',
              },
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default StatsLayout;
