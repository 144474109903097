import NiceModal from '@ebay/nice-modal-react';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { AddOutlined } from '@mui/icons-material';
import { Divider, Skeleton, Stack } from '@mui/material';

import { useExperience } from '../../../Hooks/useExperience';
import { useExperienceTags } from '../../../Hooks/useExperienceTags';
import { useTranslate } from '../../../Hooks/useTranslate';
import { CreateTagDialog } from './dialogs/create-tag';
import { TagList } from './tag-list/tag-list';

export const TagManagementSection = () => {
  return (
    <Stack
      sx={{
        backgroundColor: { sm: 'white' },
        borderStyle: { sm: 'solid' },
        borderWidth: { sm: 1 },
        borderColor: { sm: lightTheme.palette.neutral.n100 },
        borderRadius: { sm: 1 },
        paddingY: { sm: 3 },
        paddingX: { sm: 4 },
        gap: 2,
      }}
      divider={<Divider />}
    >
      <TagManagementContainerHeader />
      <TagManagementList />
    </Stack>
  );
};

const TagManagementContainerHeader = () => {
  const { t } = useTranslate('storefront.tags.header');

  return (
    <Stack
      sx={{
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        gap: 2,
        justifyContent: 'space-between',
        paddingBottom: { xs: 3, sm: 0 },
      }}
    >
      <Stack>
        <Text variant="medium">{t('title')}</Text>
        <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
          {t('description')}
        </Text>
      </Stack>
      <Button
        variant="primary"
        size="medium"
        onClick={() => NiceModal.show(CreateTagDialog)}
        leftIcon={<AddOutlined />}
      >
        {t('buttonLabel')}
      </Button>
    </Stack>
  );
};

const TagManagementList = () => {
  const { tags } = useExperienceTags();
  const { experiences } = useExperience();

  const isLoading = tags.isLoading || experiences.isLoading;

  if (isLoading) return <TagListLoadingSkeleton />;

  return <TagList tags={tags.data} experiences={experiences.data} />;
};

const TagListLoadingSkeleton = () => {
  return (
    <Stack
      sx={{ gap: 3 }}
      divider={<Divider sx={{ display: { md: 'none' } }} />}
    >
      <Skeleton width={200} />
      <Skeleton width={200} />
      <Skeleton width={200} />
    </Stack>
  );
};
