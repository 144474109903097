import type { TProvider } from '../Hooks/useMarketing';
import api, { IShallowSuccessResponse } from './index';

export type TPermission = {
  created: string;
  name: string;
  email: string;
  customerId: string;
  source: string;
};

export const getProviders = async () => {
  if (typeof process.env.REACT_APP_API_MARKETING_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_MARKETING_URL is not defined'
    );
  }

  const { data } = await api.get<TProvider[]>(
    `${process.env.REACT_APP_API_MARKETING_URL}/providers`
  );

  return data;
};

export const updateProvider = async (payload: Record<string, any>) => {
  if (typeof process.env.REACT_APP_API_MARKETING_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_MARKETING_URL is not defined'
    );
  }

  const { data } = await api.put<IShallowSuccessResponse>(
    `${process.env.REACT_APP_API_MARKETING_URL}/provider`,
    payload
  );

  return data;
};

export const getPermissions = async () => {
  if (typeof process.env.REACT_APP_API_MARKETING_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_MARKETING_URL is not defined'
    );
  }

  const { data } = await api.get<TPermission[]>(
    `${process.env.REACT_APP_API_MARKETING_URL}/consents`
  );

  return data;
};
