import api from './index';

export const initializeGoogleAuth = async () => {
  if (typeof process.env.REACT_APP_API_AUTH_GOOGLE === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_AUTH_GOOGLE is not defined'
    );
  }

  const { data } = await api.post<{ url: string }>(
    `${process.env.REACT_APP_API_AUTH_GOOGLE}`,
    {}
  );

  return data;
};

export const getTranslation = async (payload: {
  text: string;
  targetLanguageCode: string;
  sourceLanguageCode?: string;
}) => {
  if (typeof process.env.REACT_APP_API_TRANSLATE_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_TRANSLATE_URL is not defined'
    );
  }

  const { data } = await api.post<{ text: string }>(
    `${process.env.REACT_APP_API_TRANSLATE_URL}`,
    { ...payload }
  );

  return data;
};

export const initPaymentSetup = async (
  paymentMethod: string,
  accountStatus: 'new' | 'existing' | undefined
) => {
  if (typeof process.env.REACT_APP_API_PAYMENT_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PAYMENT_URL is not defined'
    );
  }

  let url = `${process.env.REACT_APP_API_PAYMENT_URL}/setup/${paymentMethod}`;

  if (accountStatus === 'existing') {
    url += `?existing=1`;
  }

  const { data } = await api.get<{ url: string }>(url);

  return data;
};

export const disconnectPayment = async (paymentMethod: string) => {
  if (typeof process.env.REACT_APP_API_PAYMENT_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_PAYMENT_URL is not defined'
    );
  }

  const url = `${process.env.REACT_APP_API_PAYMENT_URL}/setup/${paymentMethod}`;
  const { data } = await api.delete<{ url: string }>(url);

  return data;
};

export const messageParticipants = async (
  eventId: string,
  userId: string,
  message: string
) => {
  if (typeof process.env.REACT_APP_API_EVENTS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_EVENTS_URL is not defined'
    );
  }

  const { data } = await api.post(
    `${process.env.REACT_APP_API_EVENTS_URL}/${eventId}/message`,
    {
      message,
      userId,
    }
  );

  return data;
};
