import uploadAxios from 'axios';

import { TFileTypes, TModelTypes } from '../types';
import api, { IShallowSuccessResponse } from './index';
import { MediaItem } from '@holdbar-com/utils-types';

export const getSigned = async (
  propType: TFileTypes,
  modelType: TModelTypes,
  mime: string,
  id: string
) => {
  if (typeof process.env.REACT_APP_API_UPLOAD_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_UPLOAD_URL is not defined'
    );
  }

  const { data } = await api.post<{
    url: string;
    key: string;
    uploadedTo: string;
  }>(`${process.env.REACT_APP_API_UPLOAD_URL}/signed`, {
    propType,
    modelType,
    mime,
    id,
  });

  return data;
};

export const upload = async (
  file: File,
  presignedUrl: string,
  renderProgress?: (progress: number) => void,
  mime: string = 'application/x-www-form-urlencoded'
) => {
  if (!presignedUrl) {
    throw new Error('No presigned url present.');
  }

  const { data } = await uploadAxios.put<any>(presignedUrl, file, {
    headers: {
      'Content-Type': mime,
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      renderProgress?.(percentCompleted);
    },
  });

  return data;
};

export const deleteUpload = async (mediaItem?: MediaItem, url?: string) => {
  if (typeof process.env.REACT_APP_API_UPLOAD_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_UPLOAD_URL is not defined'
    );
  }

  const options = mediaItem
    ? {
        data: {
          mediaItem,
        },
      }
    : url
      ? {
          params: {
            url,
          },
        }
      : {};

  const { data } = await api.delete<IShallowSuccessResponse>(
    `${process.env.REACT_APP_API_UPLOAD_URL}`,
    options
  );

  return data;
};
