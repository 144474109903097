import { Text } from '@holdbar-com/pixel';
import { Notes } from '@mui/icons-material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {
  Box,
  BoxProps,
  Card,
  Divider,
  Grid,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { renderGuestTypes } from '../../features/bookings/booking_details/ui/booking_details_summary/domain/use_booking_details_summary';
import { TBooking } from '../../Hooks/useBookings';
import { useTranslate } from '../../Hooks/useTranslate';
import { StatusBadge } from '../badge/status-badge';
import { EmptyScreen } from '../EmptyScreen/EmptyScreen';
import { ListSkeleton } from '../ListSkeleton/ListSkeleton';

const columns = [
  {
    key: 'none',
    props: {
      xs: 0.5,
    },
    value: (el: TBooking) => (el.internalNote ? <Notes /> : ''),
    valueProps: {
      display: 'flex',
      pl: 0.5,
      alignItems: 'center',
    },
  },
  {
    key: 'name',
    props: {
      xs: 2,
    },
    value: (el: TBooking) => el.customer?.name,
  },
  {
    key: 'email',
    props: {
      xs: 2.5,
    },
    value: (el: TBooking) => el.customer?.email,
  },
  {
    key: 'phone',
    props: {
      xs: 1.6,
    },
    value: (el: TBooking) => el.customer?.phone,
  },
  {
    key: 'type',
    props: {
      xs: 2.5,
    },
    value: (el: TBooking) => renderGuestTypes(el, 'variant'),
  },
  {
    key: 'count',
    props: {
      xs: 1.2,
    },
    value: (el: TBooking) =>
      parseInt(
        String(
          Object.entries(el.items ?? {}).reduce(
            (acc, [id, count]) =>
              acc + (id.startsWith('variant') ? parseInt(`${count}`) : 0),
            0
          )
        )
      ),
    valueProps: {
      fontWeight: 600,
    },
  },
  {
    key: 'status',
    props: {},
    value: (el: TBooking) => (
      <span>
        <StatusBadge
          type="booking"
          size="small"
          state={
            el.status === 'active' && el.transaction != null
              ? 'paid'
              : el.status
          }
        />
      </span>
    ),
  },
];

const [name, phone, count, status] = columns;

export const BookingsListPage = ({
  bookings,
  showExperience = false,
  showHeaders = true,
  isLoading = false,
  ...props
}: BoxProps & {
  isLoading?: boolean;
  showExperience?: boolean;
  bookings?: TBooking[];
  showHeaders?: boolean;
}) => {
  const { t } = useTranslate('utils.tables.header');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();

  const handleClick = (bookingId: string) => () => {
    navigate(`/booking/${bookingId}`);
  };

  return (
    <Box {...props}>
      {isMobile || !showHeaders ? null : (
        <Grid container p={'12px'} mb={1}>
          {columns.map((el) => {
            const isExperience = showExperience && el.key === 'type';
            const { xs, ...props } = el.props;
            return (
              <Grid
                fontSize={'0.75em'}
                fontWeight={600}
                key={el.key}
                item
                {...props}
                xs={isExperience ? 2.2 : xs}
                component={Typography}
              >
                {el.key !== 'none' && t(isExperience ? 'experience' : el.key)}
              </Grid>
            );
          })}
        </Grid>
      )}

      <Stack spacing={1}>
        {bookings?.length === 0 && (
          <EmptyScreen
            mt={6}
            imageSrc={'/empty-screens/event.svg'}
            title={t('emptyState.title', 'bookings')}
            description={t('emptyState.description', 'bookings')}
          />
        )}

        {(bookings === null || isLoading) && <ListSkeleton />}

        {bookings &&
          [...bookings]
            ?.sort(
              (a, b) =>
                new Date(b.created).getTime() - new Date(a.created).getTime()
            )
            .map((booking) => {
              if (isMobile) {
                return (
                  <Card
                    sx={{
                      px: '12px',
                      py: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      color: '#1C1C1E',
                      fontWeight: 500,
                      fontSize: '14px',
                      minHeight: '80px',
                    }}
                    key={booking.id}
                    onClick={handleClick(booking.id)}
                  >
                    <Box sx={{ display: 'flex', gap: '8px' }}>
                      <PersonOutlineOutlinedIcon
                        sx={{ mt: '2px', flexGrow: 1 }}
                      />
                      <Box sx={{ flexGrow: 2 }}>
                        <Box>{name.value?.(booking)}</Box>
                        <Box>{count.value?.(booking)} Tickets</Box>
                        <Box>{phone.value?.(booking)}</Box>
                        {booking.internalNote ? (
                          <>
                            <Divider sx={{ my: '4px' }} />
                            <Box>{booking.internalNote}</Box>
                          </>
                        ) : null}
                      </Box>
                    </Box>
                    <Box>{status.value?.(booking)}</Box>
                  </Card>
                );
              } else {
                return (
                  <Grid
                    container
                    component={Card}
                    p={1.5}
                    key={booking.id}
                    alignItems={'center'}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: 'rgb(252,252,252)' },
                    }}
                    onClick={handleClick(booking.id)}
                  >
                    {columns.map((el) => {
                      const isExperience = showExperience && el.key === 'type';
                      const { xs, ...props } = el.props;
                      return (
                        <Grid
                          pr={isExperience ? 2 : 0}
                          fontSize={'0.88em'}
                          key={`${booking.id}-${el.key}`}
                          item
                          {...props}
                          {...el.valueProps}
                          xs={isExperience ? 2.2 : xs}
                          component={StyledListItemText}
                        >
                          {isExperience
                            ? booking.experienceHeadline
                            : el.value?.(booking)}
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              }
            })}
      </Stack>
    </Box>
  );
};

const StyledListItemText = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ::first-letter {
    text-transform: capitalize;
  }
`;
