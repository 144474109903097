import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, lightTheme, LinkButton, Text } from '@holdbar-com/pixel';
import {
  CheckRounded,
  CloseRounded,
  HelpOutlineOutlined,
} from '@mui/icons-material';
import { Box, CircularProgress, Dialog, Stack } from '@mui/material';
import { useState } from 'react';

import useResponsive from '../../../../../Hooks/layout/useResponsive';
import { useTranslate } from '../../../../../Hooks/useTranslate';
import { IntegrationObject } from '../../domain/use-integrations';

interface AvailableIntegrationDialogProps {
  integration: Pick<
    IntegrationObject,
    | 'icon'
    | 'title'
    | 'description'
    | 'extendedDescription'
    | 'openForRequest'
    | 'isRequested'
    | 'guide'
  > & {
    buttonLabel?: string;
  };
  onAddIntegration: () => Promise<void>;
}

export const AvailableIntegrationDialog =
  NiceModal.create<AvailableIntegrationDialogProps>(
    ({ integration, onAddIntegration }) => {
      const { t } = useTranslate('settings.integrations');
      const { isSm } = useResponsive();
      const modal = useModal();

      const [isLoading, setIsLoading] = useState(false);
      const [hasSubmitted, setHasSubmitted] = useState(false);

      const handleClose = () => {
        modal.reject();
        modal.hide();
      };

      const handleSubmit = async () => {
        setIsLoading(true);
        await onAddIntegration();
        setIsLoading(false);

        if (integration.openForRequest) {
          setHasSubmitted(true);
          await new Promise((resolve: any) =>
            setTimeout(() => {
              handleClose();
              resolve();
            }, 2000)
          );
        } else {
          handleClose();
        }
      };

      return (
        <Dialog
          open={modal.visible}
          onClose={handleClose}
          fullWidth
          maxWidth={'sm'}
          fullScreen={isSm}
        >
          <Stack gap={4} p={4} height={'100%'} justifyContent={'space-between'}>
            <Stack gap={2}>
              <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
                <Box
                  component="img"
                  src={integration.icon}
                  sx={{
                    width: 80,
                    height: 80,
                    borderRadius: 100,
                  }}
                />
                <CloseRounded
                  onClick={handleClose}
                  sx={{ cursor: 'pointer' }}
                />
              </Stack>
              <Stack gap={1}>
                <Stack gap={2} direction={'row'}>
                  <Text fontSize="xlarge" variant="medium">
                    {integration.title}
                  </Text>
                  {integration.guide && (
                    <LinkButton
                      variant="secondary"
                      size="small"
                      href={integration.guide}
                      leftIcon={
                        <HelpOutlineOutlined
                          fontSize="small"
                          sx={{ mr: 0.5 }}
                        />
                      }
                      target="_blank"
                    >
                      {t('helpGuide', 'utils.generic')}
                    </LinkButton>
                  )}
                </Stack>
                <Text fontSize="small" color={lightTheme.palette.neutral.n400}>
                  {integration.extendedDescription}
                </Text>
              </Stack>
            </Stack>
            <Stack
              gap={isSm ? 1 : 2}
              width={'100%'}
              direction={isSm ? 'column-reverse' : 'row'}
            >
              <Button
                variant="secondary"
                size="medium"
                onClick={handleClose}
                style={{ flexGrow: 1, maxWidth: isSm ? '100%' : '50%' }}
              >
                {t('cancel', 'utils.generic')}
              </Button>
              <Button
                variant="primary"
                size="medium"
                style={{ flexGrow: 1, maxWidth: isSm ? '100%' : '50%' }}
                disabled={isLoading || integration.isRequested || hasSubmitted}
                leftIcon={
                  isLoading ? (
                    <CircularProgress
                      color={'inherit'}
                      size={'1em'}
                      sx={{ mr: 1 }}
                    />
                  ) : (
                    (integration.isRequested || hasSubmitted) && (
                      <CheckRounded
                        fontSize={'small'}
                        sx={{ color: lightTheme.palette.success.s400, mr: 1 }}
                      />
                    )
                  )
                }
                onClick={handleSubmit}
              >
                {integration.isRequested || hasSubmitted
                  ? t('willContact', 'utils.generic')
                  : integration.buttonLabel || t('getStarted', 'utils.generic')}
              </Button>
            </Stack>
          </Stack>
        </Dialog>
      );
    }
  );
