import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Text } from '@holdbar-com/pixel';
import { CloseRounded } from '@mui/icons-material';
import { Dialog, Link, Stack } from '@mui/material';
import isEmpty from 'lodash.isempty';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useCalendarFeed } from '../../Hooks/useCalendarFeed';
import { useTranslate } from '../../Hooks/useTranslate';
import { useIcalIntegration } from '../../Sections/SettingsSections/Integrations/domain/integrations/use-ical-integration';
import { IcalDialogStepLoader } from './ical-dialog-step-loader';
import {
  icalCreateDefaultValues,
  IcalFormValues,
} from './steps/create/use-ical-dialog-create-step';

export const IcalDialog = NiceModal.create(() => {
  const { t } = useTranslate('dialogs.ical');

  const [hasLoadedInitially, setHasLoadedInitially] = useState(false);

  const modal = useModal();

  const { guide } = useIcalIntegration();

  const { feed } = useCalendarFeed();

  const { control, setValue, handleSubmit, ...methods } =
    useForm<IcalFormValues>({ defaultValues: icalCreateDefaultValues });

  useEffect(() => {
    if (!hasLoadedInitially && !feed.isLoading) {
      if (!isEmpty(feed.data)) {
        for (const [key, value] of Object.entries(feed.data)) {
          const isEnabled =
            value === null || value === undefined
              ? false
              : typeof value === 'boolean'
                ? value
                : true;
          setValue(
            key as keyof IcalFormValues,
            {
              selectedOptionKey: isEnabled,
              value: Array.isArray(value) ? [...value] : (value as any),
            },
            { shouldTouch: true }
          );
        }
        setHasLoadedInitially(true);
        return;
      }
    }
  }, [feed, hasLoadedInitially]);

  const handleClose = () => {
    modal.reject();
    modal.hide();
  };

  return (
    <Dialog
      open={modal.visible}
      onClose={handleClose}
      PaperProps={{ sx: { width: '100%', minHeight: 350 } }}
    >
      <FormProvider {...{ control, setValue, handleSubmit, ...methods }}>
        <Stack gap={3} p={4}>
          <Stack gap={4}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Stack gap={1}>
                <Text fontSize={'xlarge'} variant="medium">
                  {t('calendars.ical.title', 'settings.integrations')}
                </Text>
                <Link href={guide} target="_blank" underline="always">
                  {t('showMeHow', 'utils.generic')}
                </Link>
              </Stack>
              <CloseRounded onClick={handleClose} sx={{ cursor: 'pointer' }} />
            </Stack>
            <IcalDialogStepLoader hasLoadedInitially={hasLoadedInitially} />
          </Stack>
        </Stack>
      </FormProvider>
    </Dialog>
  );
});
