import { Text } from '@holdbar-com/pixel';
import { renderDate } from '@holdbar-com/utils-date';
import { Stack } from '@mui/material';

import { StatusBadge } from '../../../../Components/badge/status-badge';
import { TBooking } from '../../../../Hooks/useBookings';
import { Variant } from '../../../../Hooks/useExperience';
import { renderGuestTypes } from '../../../../Utils/eventHelpers';

export const EventDetailsListColumns = [
  {
    key: 'name',
    props: {},
    value: (el: TBooking) => el.customer?.name,
  },
  {
    key: 'email',
    props: {},
    value: (el: TBooking) => el.customer?.email,
  },
  // {
  //   key: 'phone',
  //   props: {},
  //   value: (el: TBooking) => el.customer?.phone,
  // },
  {
    key: 'type',
    props: {},
    value: (booking: TBooking, language?: string, variants?: Variant[]) => (
      <Stack key={`participants-type-${booking.id}`}>
        {renderGuestTypes(booking, 'variant', language, variants).map(
          (guestType, ix) => (
            <Text
              key={`participants-type-${booking.id}-${ix}`}
              fontSize="small"
            >
              {guestType}
            </Text>
          )
        )}
      </Stack>
    ),
  },
  {
    key: 'count',
    props: {},
    value: (el: TBooking) =>
      parseInt(
        String(
          Object.entries(el.items ?? {}).reduce(
            (acc, [id, count]) =>
              acc + (id.startsWith('variant') ? parseInt(`${count}`) : 0),
            0
          )
        )
      ),
  },
  {
    key: 'created',
    props: {},
    value: (el: TBooking) => renderDate(el.created, 'dd.MM.yyyy'),
  },
  {
    key: 'status',
    props: {},
    value: (el: TBooking) => (
      <StatusBadge
        type="booking"
        size="small"
        state={
          el.status === 'active' && el.transaction != null ? 'paid' : el.status
        }
      />
    ),
  },
];

export const EventDetailsListColumnWidths = {
  name: '10%',
  email: '15%',
  // phone: '9%',
  type: '25%',
  count: '10%',
  created: '10%',
  status: '10%',
} as const;
