import { captureEvent } from '@sentry/react';
import axios from 'axios';

import { useAuthStore } from '../Store/useAuthStore';
import { refreshToken } from './Auth';
export {
  initializeGoogleAuth,
  getTranslation,
  messageParticipants,
} from './Api';
export { updatePassword, update as updateUser, getRoles } from './User';
export { upload, deleteUpload, getSigned } from './Upload';
export {
  signIn,
  signOut,
  createAccount,
  addAccountToPreviewCompany,
  refreshToken,
  resetPassword,
  updateResatPassword,
  acceptInvitation,
} from './Auth';
export {
  getExperience,
  getExperiences,
  updateExperience,
  deleteExperience,
} from './Experience';
export {
  getEvents,
  getEvent,
  updateEvent,
  deleteEvent,
  cancelEvent,
  addExcludedDate,
  manifestVirtualEvent,
  getEventsInExperience,
} from './Event';
export {
  getBookings,
  getBookingsByDate,
  cancelBooking,
  refundBooking,
  createBooking,
  updateBooking,
  getBooking,
  moveBooking,
  checkInBooking,
} from './Booking';
export {
  getCompanyProfile,
  getMyProfile,
  updateCompanyFeature,
  updateCompanyProfile,
  updateMyProfile,
  getCompanyUsers,
  getUserinfo,
  firstLogin,
  removeUser,
  getPublicCompanyProfile,
} from './Profiles';
export {
  getVouchers,
  getSoldVouchers,
  updateVoucher,
  deleteVoucher,
  refundVoucher,
} from './Voucher';
export {
  getTemplates,
  getSettings,
  updateSettings,
  getNotificationLog,
  resendNotification,
} from './Notifications';
export { getAudits } from './Audits';
export { getMetrics, getMetricsForExperience } from './Analytics';
export { getTerms, createFromTemplate, updateTerms } from './Terms';
export { getOnBoarding, updateOnBoardingStep } from './OnBoarding';
export {
  getDiscounts,
  getDiscount,
  deleteDiscount,
  updateDiscount,
} from './Discount';
export { getPlatformTerms, getPlatformData } from './Platform';
export {
  getCalendarFeed,
  updateCalendarFeed,
  deleteCalendarFeed,
} from './CalendarFeeds';
export { domainAvailable, createDomain, deleteDomain } from './Domains';
export { updatePayment, getPayment } from './Payment';
export { getInvitationData } from './Invitation';
export { generateExperience } from './Generation';
export { updateProvider, getProviders, getPermissions } from './Marketing';
export { getReceipt } from './Receipt';
export { getSearchResults } from './Search';
export {
  getExperienceTags,
  createExperienceTag,
  updateExperienceTag,
  deleteExperienceTag,
} from './experience-tags';
export { getCountryVatRates } from './Tax';
export { getSuggestions, suggestionDecision } from './Suggestion';
export * from './Economic';
export * from './connect';
export * from './stock-photos';

let authRequest: null | Promise<any> = null;
let retries = 0;
const MAX_RETRIES = 10;

const clearRetries = () => (retries = 0);

declare module 'axios' {
  type ApiRequestConfig = {
    ignoredStatusCodesInReporting?: number[];
  };
  interface AxiosRequestConfig extends ApiRequestConfig {}
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL!,
  timeout: 120000,
});

const authApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL!,
  timeout: 30000,
});

authApi.interceptors.response.use(
  (res) => res,
  (err) => {
    const { clearAuth } = useAuthStore.getState();

    if (err.config.url === process.env.REACT_APP_API_AUTH_SIGNIN) {
      clearAuth();
      return;
    }
    if (err.response?.status === 401) {
      clearAuth();
      clearRetries();
      window.location.reload();
      return;
    }

    return Promise.reject(err);
  }
);

/**
 * Replaces UUIDs in pathnames with :id. This makes grouping events in Sentry better.
 * @param pathname a pathname string or undefined
 * @returns A pathname with UUIDs replace with :id - or "no pathname captured" if the input is undefined
 */
function generalizePathname(pathname?: string) {
  if (!pathname) {
    return 'no pathname captured';
  }
  return pathname.replaceAll(/\/([a-z0-9]{32})(\/|$)/gi, '/:id/');
}

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Refresh token and retry
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        const { auth, setAuth } = useAuthStore.getState();
        if (!auth) {
          return error.config;
        }
        if (retries >= MAX_RETRIES) {
          clearRetries();
          return error.config;
        }
        retries++;
        if (authRequest instanceof Promise) {
          await authRequest;
          clearRetries();
          return api.request(error.config);
        }
        authRequest = refreshToken(auth.refresh_token).then((tokens) => {
          setAuth({ ...tokens });
          authRequest = null;
        });
      }

      // Capture errors in Sentry
      const statusCode = error.response?.status || 0;

      // Do not track errors in Sentry for individually ignored status codes.
      // HTTP status 0 is a cancelled request. This happens eg. on navigation while a request is in-flight. Those should always be ignored.
      if (
        !error.config.url ||
        statusCode === 0 ||
        error.config.ignoredStatusCodesInReporting?.includes(statusCode)
      ) {
        throw error;
      }

      const url = new URL(error.config.url);
      captureEvent({
        message: `Api call failed with code ${statusCode}: ${generalizePathname(url.pathname)}`,
        extra: {
          url: url.toString(),
          response: error.response?.data ?? 'No content',
          requestHeaders: error.request.headers ?? {},
          responseHeaders: error.response?.headers ?? {},
        },
      });
    }

    throw error;
  }
);

api.interceptors.request.use(async (config) => {
  const { auth, setAuth } = useAuthStore.getState();
  if (auth === null) return config;

  let tokens: any = {};

  if (authRequest instanceof Promise) {
    await authRequest;
  }

  if (Number(new Date(auth!.expires_on!)) - 15 * 1000 <= Number(new Date())) {
    authRequest = refreshToken(auth.refresh_token);
    tokens = await authRequest;
    setAuth({ ...tokens });
    authRequest = null;
  }
  return {
    ...config,
    headers: {
      ...config.headers,
      authorization: `${tokens?.token_type ?? auth.token_type} ${tokens?.access_token ?? auth.access_token}`,
    },
  };
});

export default api;
export { authApi };

export interface IShallowSuccessResponse {
  status: 'success';
}
