import { TBoughtVoucher, TVoucher } from '../Hooks/useVoucher';
import api, { IShallowSuccessResponse } from './index';

export const getVouchers = async () => {
  if (typeof process.env.REACT_APP_API_VOUCHERS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_VOUCHERS_URL is not defined'
    );
  }

  const { data } = await api.get<TVoucher[]>(
    `${process.env.REACT_APP_API_VOUCHERS_URL}/company`
  );

  return data;
};

export const getSoldVouchers = async () => {
  if (typeof process.env.REACT_APP_API_VOUCHERS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_VOUCHERS_URL is not defined'
    );
  }

  const { data } = await api.get<TBoughtVoucher[]>(
    `${process.env.REACT_APP_API_VOUCHERS_URL}/company/sold`
  );

  return data;
};

export const updateVoucher = async (
  id: string,
  payload: Record<string, any>
) => {
  if (typeof process.env.REACT_APP_API_VOUCHERS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_VOUCHERS_URL is not defined'
    );
  }

  const { data } = await api.put<IShallowSuccessResponse>(
    `${process.env.REACT_APP_API_VOUCHERS_URL}/company/${id}`,
    payload
  );

  return data;
};

export const deleteVoucher = async (id: string) => {
  if (typeof process.env.REACT_APP_API_VOUCHERS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_VOUCHERS_URL is not defined'
    );
  }

  const { data } = await api.delete<IShallowSuccessResponse>(
    `${process.env.REACT_APP_API_VOUCHERS_URL}/company/${id}`
  );

  return data;
};

export const refundVoucher = async (id: string) => {
  if (typeof process.env.REACT_APP_API_VOUCHERS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_VOUCHERS_URL is not defined'
    );
  }

  const { data } = await api.post<IShallowSuccessResponse>(
    `${process.env.REACT_APP_API_VOUCHERS_URL}/refund/${id}`
  );

  return data;
};
