import api from './index';

export type CalendarFeedConfig = {
  language: string;
  onlyEventsWithBookings: boolean;
  accessKey?: string;
  filterGuides?: string[];
};

export const getCalendarFeed = async () => {
  if (typeof process.env.REACT_APP_API_CALENDAR_FEEDS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_CALENDAR_FEEDS_URL is not defined'
    );
  }

  const { data } = await api.get(
    `${process.env.REACT_APP_API_CALENDAR_FEEDS_URL}/feed`
  );

  return data;
};

export const updateCalendarFeed = async (payload: CalendarFeedConfig) => {
  if (typeof process.env.REACT_APP_API_CALENDAR_FEEDS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_CALENDAR_FEEDS_URL is not defined'
    );
  }

  const { data } = await api.put<{ accessKey: string }>(
    `${process.env.REACT_APP_API_CALENDAR_FEEDS_URL}/feed`,
    { ...payload }
  );

  return data;
};

export const deleteCalendarFeed = async () => {
  if (typeof process.env.REACT_APP_API_CALENDAR_FEEDS_URL === 'undefined') {
    throw new Error(
      'Environment variable REACT_APP_API_CALENDAR_FEEDS_URL is not defined'
    );
  }

  await api.delete(`${process.env.REACT_APP_API_CALENDAR_FEEDS_URL}/feed`);
};
