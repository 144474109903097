import { ampli } from '../../Ampli';
import {
  endDateEventProperties,
  startDateEventProperties,
} from './eventProperties';

export const trackOverviewRequestReportTypeOpened = () => {
  ampli.overviewRequestReportTypeOpened();
};

export const trackOverviewRequestReportTypeSelected = (report_type: string) => {
  ampli.overviewRequestReportTypeSelected({ report_type });
};

export const trackOverviewReportFirstDateOpened = () => {
  ampli.overviewReportFirstDateOpened();
};

export const trackOverviewReportFirstDateSelected = (
  new_start_date: Date,
  current_end_date: Date
) => {
  const dateEventProperties = startDateEventProperties(
    new_start_date,
    current_end_date
  );

  ampli.overviewReportFirstDateSelected(dateEventProperties);
};

export const trackOverviewReportSecondDateOpened = () => {
  ampli.overviewReportSecondDateOpened();
};

export const trackOverviewReportSecondDateSelected = (
  current_start_date: Date,
  new_end_date: Date
) => {
  const dateEventProperties = endDateEventProperties(
    current_start_date,
    new_end_date
  );

  ampli.overviewReportSecondDateSelected(dateEventProperties);
};

export const trackOverviewRequestReportCompleted = (
  report_type: string,
  userId?: string
) => {
  const properties = { report_type };

  if (userId) ampli.identify(userId, properties);
  ampli.overviewRequestReportCompleted(properties);
};
