import NiceModal from '@ebay/nice-modal-react';
import styled from '@emotion/styled';
import { Button, lightTheme, Text } from '@holdbar-com/pixel';
import { ExperienceTag } from '@holdbar-com/utils-types';
import { MoreVertOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Popover } from '@radix-ui/themes';
import { useState } from 'react';

import { StyledPopoverContent } from '../../../../Components/Popover/radix_popover_styles';
import { getLocalized } from '../../../../Hooks/useBookings';
import { IExperience } from '../../../../Hooks/useExperience';
import { useTranslate } from '../../../../Hooks/useTranslate';
import { AddTagToExperiencesDialog } from '../dialogs/add-tag-to-experiences';
import { DeleteTagDialog } from '../dialogs/delete-tag';
import { UpdateTagDialog } from '../dialogs/update-tag';

export const TagItem = ({
  tag,
  experienceCount,
  experiences,
}: {
  tag: ExperienceTag;
  experienceCount: number;
  experiences: IExperience[];
}) => {
  const { t, i18n } = useTranslate('storefront.tags.list');

  const [open, setOpen] = useState(false);

  return (
    <Stack
      key={tag.id}
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 2,
        alignItems: 'center',
      }}
    >
      <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'baseline' }}>
        <Text variant="medium" fontSize="small">
          {getLocalized(tag.name, i18n.language) ?? Object.values(tag.name)[0]}
        </Text>
        <Text
          variant="medium"
          fontSize="small"
          color={lightTheme.palette.neutral.n300}
        >
          {t('experienceCount', { count: experienceCount })}
        </Text>
      </Stack>
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger>
          <ContextButton variant="text" size="medium">
            <MoreVertOutlined fontSize="small" />
          </ContextButton>
        </Popover.Trigger>
        <StyledPopoverContent sideOffset={5} align="end">
          <Stack gap={1} width="100%" py={1}>
            <StyledContextMenuItem
              size="medium"
              variant="text"
              onClick={() =>
                NiceModal.show(AddTagToExperiencesDialog, {
                  tagId: tag.id,
                  tagName:
                    getLocalized(tag.name, i18n.language) ??
                    Object.values(tag.name)[0],
                  experiences,
                })
              }
            >
              <Text fontSize="small">{t('context.add')}</Text>
            </StyledContextMenuItem>
            <StyledContextMenuItem
              size="medium"
              variant="text"
              onClick={() => NiceModal.show(UpdateTagDialog, { tagId: tag.id })}
            >
              <Text fontSize="small">{t('context.edit')}</Text>
            </StyledContextMenuItem>
            <StyledContextMenuItem
              size="medium"
              variant="text"
              onClick={() => NiceModal.show(DeleteTagDialog, { tagId: tag.id })}
            >
              <Text fontSize="small" style={{ color: 'red' }}>
                {t('context.delete')}
              </Text>
            </StyledContextMenuItem>
          </Stack>
        </StyledPopoverContent>
      </Popover.Root>
    </Stack>
  );
};

const StyledContextMenuItem = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 8,
  padding: 8,
  borderRadius: 0,
  minWidth: 200,
  ':hover': { backgroundColor: lightTheme.palette.neutral.n50 },
});

const ContextButton = styled(Button)({
  padding: 0,
  height: '24px',
  width: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  transition: 'opacity 0.1s ease-in-out',
  '&:hover': {
    opacity: 0.75,
  },
  '& > span': {
    display: 'flex',
  },
});
