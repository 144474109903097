import { lightTheme, Text } from '@holdbar-com/pixel';
import { renderDateTime } from '@holdbar-com/utils-date';
import { CloseRounded } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  StackProps,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { addDays, addMonths, parseISO } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OverviewPageViewedProperties } from '../../Ampli';
import { Graph } from '../../Components/Graph/Graph';
import { DateInput } from '../../Components/inputs/date-input';
import { OnBoardingWidget } from '../../Components/OnBoardingWidget/OnBoardingWidget';
import { useExperience } from '../../Hooks/useExperience';
import { useOnBoarding } from '../../Hooks/useOnBoarding';
import { useProfile } from '../../Hooks/useProfile';
import { useTranslate } from '../../Hooks/useTranslate';
import { ErrorSignOutDialog } from '../../Modals/ErrorSignOutDialog';
import {
  trackOverviewEndDateOpened,
  trackOverviewEndDateSelected,
  trackOverviewMetricTileClicked,
  trackOverviewStartDateOpened,
  trackOverviewStartDateSelected,
} from '../../tracking/overview/statistics';
import { trackOverviewExperienceFilterExited } from '../../tracking/overview/trackOverviewExperienceFilterExited';
import { trackOverviewExperienceFilterOpened } from '../../tracking/overview/trackOverviewExperienceFilterOpened';
import { trackOverviewExperienceFilterSelected } from '../../tracking/overview/trackOverviewExperienceFilterSelected';
import { trackOverviewExperienceFilterStarted } from '../../tracking/overview/trackOverviewExperienceFilterStarted';
import { trackOverviewPageViewed } from '../../tracking/overview/trackOverviewPageViewed';
import StatsLayout from './MobileStatsLayout';
import { QuickSetupSection } from './quick-setup-section';
import { StatsCard } from './StatsCard';
import { useMetrics } from './useMetrics';

const itemsConfig = {
  allowedOnMobile: ['impressions', 'guests', 'customers'],
  featured: 'revenue',
};

type DashboardOverviewSectionProps = {
  setOverviewTrackingData: Dispatch<
    SetStateAction<OverviewPageViewedProperties | undefined>
  >;
};

export const DashboardOverviewSection = ({
  setOverviewTrackingData,
}: DashboardOverviewSectionProps) => {
  const { t, i18n } = useTranslate('dashboard');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { onboardingCompleted, defaultCurrency, me, company } = useProfile();
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const {
    onBoarding: { data: { items: onBoarding = [] } = { items: [] } },
  } = useOnBoarding();

  useEffect(() => {
    setTimeout(() => {
      if (
        me.isSuccess &&
        !me.isLoading &&
        !company.isSuccess &&
        company.isLoading
      ) {
        setShowErrorDialog(true);
      } else {
        setShowErrorDialog(false);
      }
    }, 10000);
  }, [me.isSuccess, company.isSuccess, me.isLoading, company.isLoading]);

  const [fromDate, setFromDate] = useState(() => addDays(new Date(), -7));
  const [toDate, setToDate] = useState(() => new Date());
  const [experienceFilter, setExperienceFilter] = useState<string | null>(null);
  const [selectedMetric, setSelectedMetric] = useState(0);
  const [hasTrackedView, setHasTrackedView] = useState(false);

  const {
    isLoading,
    isError,
    accumulatedMetricsItems,
    graphSeries,
    metricNames,
    metadata,
  } = useMetrics({
    from: fromDate,
    to: toDate,
    experience: experienceFilter,
    selectedMetric: selectedMetric,
  });

  useEffect(() => {
    if (accumulatedMetricsItems && !isLoading && !hasTrackedView) {
      setHasTrackedView(true);
      const properties = trackOverviewPageViewed(
        accumulatedMetricsItems,
        defaultCurrency,
        fromDate,
        toDate,
        me.data?.id
      );
      setOverviewTrackingData(properties);
    }
  }, [
    accumulatedMetricsItems,
    isLoading,
    defaultCurrency,
    fromDate,
    toDate,
    me,
  ]);

  const selectedMetricName = metricNames[selectedMetric];

  const flags = useFlags();

  const metricsToRender = accumulatedMetricsItems
    ? accumulatedMetricsItems.map((item) => ({
      key: item.key,
      value: item.value?.toLocaleString(i18n.language),
      allowedOnMobile: itemsConfig.allowedOnMobile.includes(item.key),
      featured: item.key === itemsConfig.featured,
    }))
    : [];

  const handleSelectMetric = (index: number) => {
    setSelectedMetric(index);
    trackOverviewMetricTileClicked(metricNames[index]);
  };

  const showQuickSetup = useMemo(
    () =>
      flags.featureOnboardingFlow &&
      onBoarding.find((step) => step.key === 'experience')?.completed === false,
    [flags.featureOnboardingFlow, onBoarding]
  );

  if (onboardingCompleted === false) {
    return (
      <Stack gap={4} mt={6}>
        {showQuickSetup && <QuickSetupSection />}
        <OnBoardingWidget />
      </Stack>
    );
  }

  if (onboardingCompleted === undefined) {
    return (
      <Box mt={4}>
        <StatsCard
          sx={{ width: '240px', height: '100%' }}
          title={undefined}
          value={undefined}
          label={''}
        />
      </Box>
    );
  }

  return (
    <Stack gap={4} py={4}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        width={'100%'}
      >
        <DashboardPeriodSelector
          sx={{ width: { xs: '100%', md: 'auto' } }}
          loading={isLoading}
          onChange={(from, to) => {
            setFromDate(from);
            setToDate(to);
          }}
        />
        <ExperienceFilter
          sx={{ display: { xs: 'none', md: 'inline-flex' } }}
          experience={experienceFilter}
          onChange={(experienceId) => {
            setExperienceFilter(experienceId);
          }}
        />
      </Stack>

      {isError ? (
        <Text>{t('statistics.metricsFetchError')}</Text>
      ) : (
        <>
          {isMobile ? (
            /* mobile statscards */
            <StatsLayout
              selectedMetric={selectedMetric}
              setSelectedMetric={handleSelectMetric}
              defaultCurrency={defaultCurrency}
              t={t}
              items={metricsToRender}
              isLoading={isLoading}
            />
          ) : (
            /* desktop statscard */
            <Grid
              sx={{ display: { xs: 'none', md: 'flex' } }}
              container
              spacing={2}
              columns={3}
              maxWidth={800}
            >
              {metricsToRender.map(({ value, ...el }, i) => {
                return (
                  <Grid item xs={1} key={el.key}>
                    <StatsCard
                      value={
                        ['revenue', 'netRevenue', 'refunds'].includes(el.key)
                          ? `${value} ${t(defaultCurrency, 'utils.currency')}`
                          : value
                      }
                      isSelected={selectedMetric === i && !isLoading}
                      onClick={() => handleSelectMetric(i)}
                      title={
                        t(`statistics.cards.${el.key}`) +
                        (el.key !== 'impressions' ? '*' : '')
                      }
                      tooltip={t(
                        `statistics.cards.tooltips.${el.key}`,
                        undefined,
                        {
                          defaultValue: '',
                        }
                      )}
                      {...el}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
          <Box
            component={Card}
            sx={{
              width: '100%',
              height: {
                xs: '300px',
                md: '400px',
              },
            }}
          >
            <Graph
              labels={Object.keys(graphSeries ?? {})}
              series={[
                {
                  name: t(`statistics.cards.${selectedMetricName}`),
                  type: 'line',
                  data: [...(Object.values(graphSeries) as number[])],
                },
                {
                  name: t(`statistics.cards.${selectedMetricName}`),
                  type: 'bar',
                  data: [...(Object.values(graphSeries) as number[])],
                },
              ]}
            />
          </Box>
        </>
      )}
      <Stack gap={1}>
        {metadata && (
          <Stack
            direction={isMobile ? 'column' : 'row'}
            gap={1}
            divider={!isMobile && <Divider sx={{ borderWidth: '0.5px' }} />}
          >
            <Text fontSize={'small'} color={lightTheme.palette.neutral.n300}>
              {t('metadata.lastUpdated')}:{' '}
              {renderDateTime(metadata.dataUpdateTimeUtc)}
            </Text>
            <Text fontSize={'small'} color={lightTheme.palette.neutral.n300}>
              {t('metadata.nextUpdate')}:{' '}
              {renderDateTime(metadata.nextDataUpdateTimeUtc)}
            </Text>
          </Stack>
        )}
        <Text fontSize="small" color={lightTheme.palette.neutral.n300}>
          * {t('statistics.cards.tooltipDisclaimer')}
        </Text>
      </Stack>

      {showErrorDialog && (
        <ErrorSignOutDialog setShowErrorDialog={setShowErrorDialog} />
      )}
    </Stack>
  );
};

type PeriodSelectorProps = Omit<StackProps, 'onChange'> & {
  onChange: (from: Date, to: Date) => void;
  loading?: boolean;
};

const DashboardPeriodSelector = ({
  onChange,
  loading = false,
  ...props
}: PeriodSelectorProps) => {
  const { t } = useTranslate('utils.generic');

  const [from, setFrom] = useState(addDays(new Date(), -7));
  const [to, setTo] = useState(new Date());

  const maxDate = addMonths(new Date(), 3).toISOString();

  const handleChange = (key: 'from' | 'to') => (value: Date) => {
    const isFrom = key === 'from';

    const [newFrom, newTo] = isFrom ? [value, to] : [from, value];

    if ((isFrom && value > to) || (!isFrom && value < from)) {
      setFrom(value);
      setTo(value);
      onChange(value, value);
    } else {
      if (isFrom) setFrom(value);
      else setTo(value);

      onChange(newFrom, newTo);
    }
  };

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={3}
      {...props}
      alignItems={'center'}
    >
      <DateInput
        label={t('from')}
        value={from}
        onChange={(date: string) => {
          const parsedDate = parseISO(date);
          handleChange('from')(parsedDate);
          trackOverviewStartDateSelected(parsedDate, to);
        }}
        onClick={trackOverviewStartDateOpened}
        minDate="2022-01-01"
        maxDate={maxDate}
        disabled={loading}
        size="small"
      />
      <DateInput
        label={t('to')}
        value={to}
        onChange={(date: string) => {
          const parsedDate = parseISO(date);
          handleChange('to')(parsedDate);
          trackOverviewEndDateSelected(from, parsedDate);
        }}
        onClick={trackOverviewEndDateOpened}
        minDate={from}
        maxDate={maxDate}
        disabled={loading}
        size="small"
      />

      {loading && (
        <Box fontSize={'0.88em'}>
          <CircularProgress size={'2em'} />
        </Box>
      )}
    </Stack>
  );
};

type ExperienceFilterProps = Omit<BoxProps, 'onChange'> & {
  experience: string | null;
  onChange: (experienceId: string | null) => void;
};

const ExperienceFilter = ({
  experience,
  onChange,
  ...props
}: ExperienceFilterProps) => {
  const { i18n } = useTranslation();
  const {
    experiences: { data: experiences },
  } = useExperience();
  const { t } = useTranslate('events.create.details');
  const [open, setOpen] = useState(false);

  const handleShowFilter = () => {
    setOpen(true);
    trackOverviewExperienceFilterStarted();
  };

  const handleExitFilter = () => {
    onChange(null);
    setOpen(false);
    trackOverviewExperienceFilterExited();
  };

  const handleOpenFilter = () => {
    trackOverviewExperienceFilterOpened(experiences?.length ?? 0);
  };

  const handleSelectExperience = (evt: SelectChangeEvent<string>) => {
    onChange(evt.target.value);
    trackOverviewExperienceFilterSelected(evt.target.value, experiences);
  };

  return (
    <Box {...props}>
      {open ? (
        <Box display={'flex'} alignItems={'center'}>
          <IconButton
            color={'error'}
            size={'small'}
            sx={{ mr: 1 }}
            onClick={handleExitFilter}
          >
            <CloseRounded />
          </IconButton>
          <FormControl sx={{ minWidth: 260, maxWidth: 260 }}>
            <InputLabel
              sx={{ backgroundColor: 'white', pl: 0.5, pr: 0.5 }}
              id="select-experience"
            >
              {t('buttons.selectExperience')}
            </InputLabel>
            <Select
              labelId="select-experience"
              value={experience ?? ''}
              onOpen={handleOpenFilter}
              onChange={handleSelectExperience}
            >
              {experiences?.map((el) =>
                el?.headline?.[i18n.language] ??
                  el?.headline?.[el.languages[0]] ? (
                  <Box
                    component={MenuItem}
                    key={el.id}
                    value={el.id}
                    justifyContent={'space-between'}
                  >
                    <Box display={'flex'} alignItems={'center'} mr={2}>
                      <Box
                        component={'img'}
                        src={el?.media?.primaryImage?.url}
                        width={24}
                        height={24}
                        borderRadius={1}
                        mr={1}
                      />
                      {el?.headline?.[i18n.language] ??
                        el?.headline?.[el.languages[0]] ??
                        ''}
                    </Box>
                  </Box>
                ) : null
              )}
            </Select>
          </FormControl>
        </Box>
      ) : (
        <Button onClick={handleShowFilter}>
          {t('buttons.filterExperiences')}
        </Button>
      )}
    </Box>
  );
};
